import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static targets = [
    'inputHidden',
    'progressBar',
    'inputDestoyHidden',
    'boxPic',
    'submitButton',
    'boxPicContainer',
    'boxPicTemplate',
    'photosCounter',
    'emptyText',
    'overlayWithLoader'
  ]
  static values = {
    uploadUrl: String,
    defaultInput: Array
  }

  inputValue = []
  destroyValue = []
  currentBoxPic = null

  MAX_FILES = 10
  MAX_FILE_SIZE = 8 * 1024 * 1024 // 5MB
  ALLOWED_FORMATS = ['image/jpeg', 'image/png', 'image/avif', 'image/webp']

  connect() {
    this.inputValue = this.defaultInputValue
  }

  uploadFile(e) {
    const file = e.target.files[0]

    if (!this.validateFile(file)) {
      return
    }

    if (file) {
      this.overlayWithLoaderTarget.classList.remove('hidden')
      this.emptyTextTarget.classList.add('tw-hidden')
      this.currentBoxPic = this.cloneBoxPic()
      this.submitButtonTarget.disabled = true
      const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)
      this.progressBarTarget.style.width = '5%'
      this.progressBarTarget.style.display = 'block'

      upload.create((error, blob) => {
        if (error) {
          throw new Error(error)
        }
        this.progressBarTarget.style.display = 'none'

        const imageUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
        this.currentBoxPic.style.backgroundImage = `url(${imageUrl})`
        this.currentBoxPic.setAttribute('data-signed-id', blob.signed_id)
        this.inputValue.push(blob.signed_id)
        this.inputHiddenTarget.value = this.inputValue
        this.inputHiddenTarget.setAttribute('value', this.inputValue)
        this.submitButtonTarget.disabled = false
        this.replaceCounterText()
        this.overlayWithLoaderTarget.classList.add('hidden')
      })
    }
  }

  replaceCounterText() {
    this.photosCounterTarget.innerText = this.photosCounterTarget.innerText.replace(/\(\d+\)/, `(${this.inputValue.length})`)
  }

  // Ajouter message d'erreur plutot que alert
  validateFile(file) {
    if (this.inputValue.length >= this.MAX_FILES) {
      alert(`Vous ne pouvez pas uploader plus de ${this.MAX_FILES} photos.`)
      return false
    }

    if (file.size > this.MAX_FILE_SIZE) {
      alert(`La taille du fichier ne doit pas dépasser ${this.MAX_FILE_SIZE / (1024 * 1024)}MB.`)
      return false
    }

    if (!this.ALLOWED_FORMATS.includes(file.type)) {
      alert(`Le format du fichier est incorrect. Formats autorisés: ${this.ALLOWED_FORMATS.join(', ')}.`)
      return false
    }

    return true
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event => {
      this.progressUpdate(event)
    })
  }

  progressUpdate(event) {
    const progress = (event.loaded / event.total) * 100
    this.currentBoxPic.style.opacity = event.loaded / event.total
    this.progressBarTarget.style.width = `${progress}%`
  }

  removeBoxPic(event) {
    const currentBoxPic = event.currentTarget.closest('#boxPic')
    const currentId = currentBoxPic.getAttribute('data-signed-id')
    this.inputValue = this.inputValue.filter(id => id !== currentId)
    this.inputHiddenTarget.value = this.inputValue
    this.inputHiddenTarget.setAttribute('value', this.inputValue)
    this.destroyValue.push(currentId)
    this.inputDestoyHiddenTarget.value = this.destroyValue
    this.inputDestoyHiddenTarget.setAttribute('value', this.destroyValue)
    currentBoxPic.remove()
    this.replaceCounterText()
  }

  cloneBoxPic() {
    const clone = this.boxPicTemplateTarget.cloneNode(true)
    this.boxPicContainerTarget.appendChild(clone)
    clone.setAttribute('data-components--ui--preview-pics-target', 'boxPic')
    const lastIndex = this.boxPicTargets.length - 1
    const currentBoxPic = this.boxPicTargets[lastIndex]
    currentBoxPic.classList.remove('tw-hidden')
    if (currentBoxPic.classList.contains('hidden')) {
      currentBoxPic.classList.remove('hidden')
    }
    currentBoxPic.setAttribute('data-index', lastIndex)
    return currentBoxPic
  }
}
