import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { id: Number }

  connect() {
    this.channel = App.cable.subscriptions.create({ channel: 'UserAppChannel', id: this.idValue }, { received: data => this.renderPartial(data) })
  }

  renderPartial(data) {
    // Partials
    const { task, user, userapp_show_data, userapp_index_data, userapp_done_data, userapp_request_data } = data

    // USER_APP SHOW
    const userAppTaskShow = document.querySelector(`#user_${user.id}_AppShow_task_${task.id}`)
    if (userAppTaskShow && userapp_show_data) {
      userAppTaskShow.parentNode.innerHTML = userapp_show_data
    }

    // USER_APP INDEX
    const userAppIndex = document.querySelector(`#userAppIndex_user_${user.id}`)
    if (userAppIndex && userapp_index_data) {
      userAppIndex.outerHTML = userapp_index_data
    }

    // USER_APP DONE TASKS
    const userAppDone = document.querySelector(`#userAppDone_user_${user.id}`)
    if (userAppDone && userapp_done_data) {
      userAppDone.outerHTML = userapp_done_data
    }

    // USER_APP PENDING REQUESTS
    const userAppRequest = document.querySelector(`#userAppRequest_user_${user.id}`)
    if (userAppRequest && userapp_request_data) {
      userAppRequest.outerHTML = userapp_request_data
    }
  }

  disconnect() {
    this.channel.unsubscribe()
  }
}
