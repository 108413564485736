import { application } from 'controllers'

const controllers = import.meta.globEager('./**/*index.js')
Object.keys(controllers).forEach(path => {
  if (!controllers[path].Controller) return

  // Convert path into a controller identifier:
  //   example/index.js -> example
  //   nav/user_info/index.js -> nav--user-info
  const identifier = path
    .replace(/^\.\//, '')
    .replace(/\/index\.js$/, '')
    .replace(/\//g, '--')

  application.register(identifier, controllers[path].Controller)
})
