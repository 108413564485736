import { Controller } from 'stimulus'
import { Provider } from 'api/jana/provider'

export default class extends Controller {
  static targets = ['userEmail', 'existingUserID', 'addExistingUser', 'userProfile', 'userForm']
  #user = null

  validateEmail(event) {
    if (this.userFormTarget.id !== 'new_user') return
    let email = this.userEmailTarget.value
    let addExistingUser = this.addExistingUserTarget
    let userID = this.existingUserIDTarget
    let profile = this.userProfileTarget.value
    if (addExistingUser.value == '' && email.length) {
      event.preventDefault();
      Provider.search_by_email(email).then(data => {
        if (data.length) this.#user = data[0]
        if (this.#user !== null && this.#user.profile == 'provider' && profile == 'provider') {
          let result = confirm(`Vous semblez vouloir ajouter un utilisateur qui existe déjà chez Jana.\nIl d'agit de ${this.#user.first_name} ${this.#user.last_name} - ${this.#user.email} - ${this.#user.phone_number}.\n\nSouhaitez-vous confirmer l’ajout de cet utilisateur ? Les informations utilisées seront celles décrites ci-dessus.`)
          if (result == true) {
            addExistingUser.value = true
            userID.value = this.#user.id
          } else {
            addExistingUser.value = false
            userID.value = ''
          }
        } else {
          addExistingUser.value = false
          userID.value = ''
        }
      }).then(() => {
        Rails.fire(this.userFormTarget, 'submit')
      }).then(() => {
        addExistingUser.value = ''
        userID.value = ''
      })
    }
  }
}