import { Controller } from 'stimulus'
import { Guest } from 'api/jana/guest'
import { BookingsCommission } from 'api/jana/bookings/commission'
import { VendorCategory } from '../../api/jana/vendorCategory'
import { Booking } from '../../api/jana/booking'
import { Owner } from '../../api/jana/owner'
import { fadeIn, show } from '../../utils/animationMethods'
import { apiFetch } from '../../api/jana/apiWrapper'
import { type } from 'jquery'

export default class extends Controller {
  FORMTYPES = {
    basic: 'basic',
    transportation: 'transportation',
    ski_pass: 'skiPass',
    ski_rental: 'skiRental'
  }

  #vendorCommissions = []
  #vendor = null
  #guest = null
  #guestStays = []
  #vendorHasChanged = false
  #firstVendorLoad = true

  static targets = [
    'addPerson',
    'vendorSelect',
    'vehicleSelect',
    'categorySelect',
    'serviceSelect',
    'radioParty',
    'radioGuest',
    'guestInput',
    'partyInput',
    'paymentChoice',
    'recurrence',
    'confirmedWithContainer',
    'confirmedWithInput',
    'transportationInputs',
    'flightTransportInputs',
    'skiPassInputs',
    'skiRentalInputs',
    'stayInput',
    'projectInput',
    'endDate',
    'bookingTermsAndConditionsEn',
    'bookingTermsAndConditionsFr',
    'commissionKind',
    'commissionValue',
    'commissionMethod',
    'commissionFor',
    'price',
    'vat',
    'vendorPrice',
    'vendorVat',
    'commission',
    'commissionField',
    'startDate',
    'startHour',
    'bookingId',
    'formContainer',
    'dynamicFormBlock',
    'newGuest',
    'newVendorCategory',
    'errorDateNotStay',
    'errorMultipleStays',
    'errorEditMultipleStays'
  ]

  static values = { bookingid: String, currentStay: String, currentProject: String }

  connect() {
    this.#firstVendorLoad = true
    if (this.hasCategorySelectTarget) {
      this.dynamicFields(this.categorySelectTarget.selectedOptions[0].text)
      const categoryId = this.categorySelectTarget.selectedOptions[0].value
      this.newVendorCategoryTarget.value = categoryId
      fetch(`/api/v1/find_vendors?id=${categoryId}`)
        .then(response => response.json())
        .then(data => {
          this.vendorSelectTarget.tomselect.clearOptions()
          data.forEach(vendor => this.vendorSelectTarget.tomselect.addOption({ value: vendor.id, text: vendor.name }))
        })
    }
    if (this.hasVendorSelectTarget) {
      if (this.vendorSelectTarget.value !== '') this.setVendor(this.vendorSelectTarget.value, true)
    }
    if (this.hasServiceSelectTarget) {
      if (this.serviceSelectTarget.value) this.displayFavoriteVendors(this.serviceSelectTarget.value, false)
    }
    let guestSelect = this.guestInputTarget.querySelector('select')

    if (this.hasCommissionMethodTarget) {
      this.displayCommissionElements(!['no', 'undefined'].includes(this.commissionKindTarget.value))

      this.commissionKindTarget.addEventListener('change', () => {
        this.displayCommissionElements(!['no', 'undefined'].includes(this.commissionKindTarget.value))
      })
      this.commissionFieldTargets.forEach(commissionElement => {
        commissionElement.addEventListener('change', () => {
          if (this.commissionKindTarget.value === 'undefined') return

          let commissionData = this.getCommissionData()
          commissionData.changeFromVendorPrice = commissionElement == this.vendorPriceTarget
          BookingsCommission.create(commissionData).then(data => this.setCommissionData(data))
        })
      })
    }

    this.stayInputTarget.addEventListener('change', () => this.setVendorCommission())
    this.startDateTarget._flatpickr.config.onChange.push(() => this.setVendorCommission())
    this.startHourTarget._flatpickr.config.onChange.push(() => this.setVendorCommission())
    this.startDateTarget.addEventListener('change', () => this.startDateChange())
  }

  setCommissionData(data) {
    this.priceTarget.value = data['price']
    this.vendorPriceTarget.value = data['vendor_price']
    this.commissionValueTarget.value = data['commission_value']
    this.commissionTarget.value = data['commission']
  }

  getCommissionData() {
    let commissionData = {}

    commissionData.price = this.priceTarget.value
    commissionData.vat = this.vatTarget.value
    commissionData.vendorPrice = this.vendorPriceTarget.value
    commissionData.vendorVat = this.vendorVatTarget.value
    commissionData.commissionValue = this.commissionValueTarget.value
    commissionData.commission = this.commissionTarget.value
    commissionData.commissionKind = this.commissionKindTarget.options[this.commissionKindTarget.selectedIndex].value
    commissionData.commissionMethod = this.commissionMethodTarget.options[this.commissionMethodTarget.selectedIndex]?.value || 'percentage'

    return commissionData
  }
  /**
   * Change the category select value depending on the vendor selected
   * @param {HTMLEvent} event
   * @return {void}
   */
  vendorSelectChange(event) {
    this.setVendor(event.target.value, false, this.#firstVendorLoad)

    if (!event.target.value || (this.#firstVendorLoad && !this.isNewAction())) {
      this.#firstVendorLoad = false
    }
  }

  setVendor(vendorId, noVendorCommission = false, noAlert = false) {
    fetch(`/api/v1/vendor?id=${vendorId}`)
      .then(response => response.json())
      .then(data => {
        this.categorySelectTarget.tomselect.setValue(data.vendor_category_id, true)
        this.dynamicFields(null)
        this.loadTermsAndConditionsOnVendorChange(vendorId)
        this.#vendorCommissions = data.vendor_commissions

        this.#vendorHasChanged = this.#vendor?.id !== data?.id
        this.#vendor = data
        if (!noVendorCommission) this.setVendorCommission(noAlert)
      })
  }

  async setVendorCommission(noAlert = false) {
    let vendorCommissionsLength = this.#vendorCommissions.length

    let commission = null
    let ownerWithContractCommission = this.#vendorCommissions.find(c => c.for === 'owner_under_contract') !== undefined
    let guestWithoutStayCommission = this.#vendorCommissions.find(c => c.for === 'guest_without_stay') !== undefined
    let owner = null
    if (ownerWithContractCommission && this.#guest?.owner_id != null) owner = await Owner.find(this.#guest.owner_id)

    if (vendorCommissionsLength > 1 && this.#guest?.owner_id != null && !owner?.under_contract) {
      commission = this.#vendorCommissions.find(c => c.for === 'owner')
    } else if (
      guestWithoutStayCommission &&
      this.#guest != null &&
      this.#guest.owner_id == null &&
      !this.bookingStartDateDuringAStay() &&
      this.stayInputTarget.value == ''
    ) {
      commission = this.#vendorCommissions.find(c => c.for === 'guest_without_stay')
    } else if (ownerWithContractCommission && this.#guest?.owner_id !== undefined && owner?.under_contract) {
      commission = this.#vendorCommissions.find(c => c.for === 'owner_under_contract')
    } else {
      commission = this.#vendorCommissions.find(c => c.for === 'guest')
    }

    if (
      commission !== undefined &&
      ((this.commissionForTarget.value === commission.for && !this.#vendorHasChanged) || this.guestInputTarget.querySelector('select').value == '')
    ) {
      this.#vendorHasChanged = false
      return
    }

    if (this.commissionForTarget.value === commission?.for && !this.#vendorHasChanged) return

    this.commissionForTarget.value = commission === undefined ? '' : commission.for

    this.#vendorHasChanged = false
    if (!this.isNewAction() && noAlert) return

    if (vendorCommissionsLength === 0) {
      if (this.isNewAction() && this.hasCommissionMethodTarget) {
        this.commissionKindTarget.value = 'undefined'
        this.commissionMethodTarget.value = 'percentage'
        this.commissionValueTarget.value = '0.0'
        this.displayCommissionElements(false)
      }
      return
    }

    if (!noAlert) alert('Attention, cette modification peut avoir un impact sur les commissions. Avertissez un administrateur si nécessaire.')

    if (!this.hasCommissionMethodTarget) return

    this.commissionValueTarget.value = commission.value
    this.commissionKindTarget.value = commission.kind
    this.commissionMethodTarget.value = commission.method
    this.vendorVatTarget.value = this.#vendor.vat

    this.commissionValueTarget.dispatchEvent(new Event('change'))

    if (['no', 'undefined'].includes(this.commissionKindTarget.value)) {
      this.commissionValueTarget.value = '0.0'
      this.displayCommissionElements(false)
    } else {
      this.displayCommissionElements(true)
    }
  }

  bookingStartDateDuringAStay() {
    const bookingDate = new Date(`${this.startDateTarget.value}`)
    bookingDate.setHours(0, 0, 0, 0)
    let stayStartDate = null
    let stayEndDate = null

    this.#guestStays.some(stay => {
      stayStartDate = new Date(stay.start_date)
      stayEndDate = new Date(stay.end_date)

      stayStartDate.setHours(0, 0, 0, 0)
      stayEndDate.setHours(0, 0, 0, 0)

      return bookingDate >= stayStartDate && bookingDate <= stayEndDate
    })

    return bookingDate >= stayStartDate && bookingDate <= stayEndDate
  }

  displayCommissionElements(condition) {
    this.commissionValueTarget.parentElement.parentElement.classList.toggle('d-none', !condition)
    this.commissionTarget.parentElement.parentElement.classList.toggle('d-none', !condition)
    this.commissionMethodTarget.parentElement.classList.toggle('d-none', !condition)
  }

  async startDateChange() {
    let guestSelect = this.guestInputTarget.querySelector('select')
    if (this.guestInputTarget.value === '') return
    if (this.startDateTarget.value === '' || this.startDateTarget.value === undefined) return
    this.errorMultipleStaysTarget.classList.add('d-none')
    this.errorDateNotStayTarget.classList.add('d-none')
    this.errorEditMultipleStaysTarget.classList.add('d-none')
    const stays = await apiFetch('stays', {
      params: {
        filter: {
          by_date: this.startDateTarget.value,
          guest_id: guestSelect.value
        }
      }
    })
    const projects = await apiFetch('client-projects', {
      params: {
        filter: {
          by_date: this.startDateTarget.value,
          guest_id: guestSelect.value
        },
        sort: 'end_date'
      }
    })
    if (this.stayOrProjectNotSelected()) {
      if (stays.length === 1 && projects.length === 0) return this.updateStayField(stays[0], 'stay')
      if (stays.length === 0 && projects.length === 1) return this.updateStayField(projects[0], 'project')
      if (stays.length > 0 && projects.length > 0) {
        this.stayInputTarget.tomselect.clear()
        return this.errorMultipleStaysTarget.classList.remove('d-none')
      }
      this.stayInputTarget.tomselect.clear()
    } else {
      if (stays.length === 1 && projects.length === 0 && stays[0].id == this.currentStayValue) return
      if (stays.length === 0 && projects.length === 1 && projects[0].id == this.currentProjectValue) return
      if (
        (stays.length === 1 && projects.length === 0 && stays[0].id !== this.currentStayValue) ||
        (stays.length === 0 && projects.length === 1 && projects[0].id !== this.currentProjectValue) ||
        (stays.length === 0 && projects.length === 1 && projects[0].id !== this.currentProjectValue)
      ) {
        return this.errorDateNotStayTarget.classList.remove('d-none')
      }
      if (stays.length === 1 && projects.length === 0) {
        return this.errorEditMultipleStaysTarget.classList.remove('d-none')
      }
      if (stays.length > 0 && projects.length > 0) {
        return this.errorEditMultipleStaysTarget.classList.remove('d-none') // Changer le wording "ne correspond plus"
      }
      return this.errorDateNotStayTarget.classList.remove('d-none')
    }
  }

  stayOrProjectNotSelected() {
    return this.currentProjectValue === '' && this.currentStayValue === ''
  }

  updateStayField(stay, className) {
    const stayTsInput = this.stayInputTarget.tomselect
    return stayTsInput.setValue(`${className}_${stay.id}`, false)
  }

  stayAndProjectIsEmpty() {
    if (!this.hasStayInputTarget) return false
    return this.stayInputTarget.tomselect.getValue() === ''
  }

  /**
   *  Change the vendor select options depending on the category selected
   * @param {HTMLEvent} event
   * @return {void}
   */
  categorySelectChange(event) {
    if (event.target.value === '' || event.target.value === null) {
      if (this.hasServiceSelectTarget) {
        // Update guest app services from vendor cat
        fetch(`/api/v1/find_guest_app_services`)
          .then(response => response.json())
          .then(data => {
            this.serviceSelectTarget.tomselect.clearOptions()
            data.forEach(service => this.serviceSelectTarget.tomselect.addOption({ value: service.id, text: service.title }))
          })
      }
      fetch(`/api/v1/find_vendors`)
        .then(response => response.json())
        .then(data => {
          this.vendorSelectTarget.tomselect.clearOptions()
          data.forEach(vendor => this.vendorSelectTarget.tomselect.addOption({ value: vendor.id, text: vendor.name }))
        })
      this.dynamicFields(null)
    } else if (event.target.value) {
      this.newVendorCategoryTarget.value = event.target.value
      // Update Vendors from vendor cat
      Booking.form(null, event.target.value).then(data => {
        if (!data) return
        this.dynamicFormBlockTarget.innerHTML = data.html_form
        this.formDocumentChange()
      })
      fetch(`/api/v1/find_vendors?id=${event.target.value}`)
        .then(response => response.json())
        .then(data => {
          this.vendorSelectTarget.tomselect.clear(true)
          this.serviceSelectTarget.tomselect.clear(true)
          this.vendorSelectTarget.tomselect.clearOptions()
          data.forEach(vendor => this.vendorSelectTarget.tomselect.addOption({ value: vendor.id, text: vendor.name }))
        })
      this.dynamicFields(event.target.selectedOptions[0].text)
      // Update guest app services from vendor cat
      if (this.hasServiceSelectTarget) {
        fetch(`/api/v1/find_services_by_vendor_category?category_id=${event.target.value}`)
          .then(response => response.json())
          .then(data => {
            if (this.serviceSelectTarget.value === '' || this.serviceSelectTarget.value === null) {
              this.serviceSelectTarget.tomselect.clearOptions()
              data.forEach(service => this.serviceSelectTarget.tomselect.addOption({ value: service.id, text: service.title }))
            }
          })
      }
    }
  }

  formDocumentChange() {
    const gmapController = this.application.getControllerForElementAndIdentifier(this.formContainerTarget, 'forms--gmaps-auto-complete')
    const selectUiController = this.application.getControllerForElementAndIdentifier(this.formContainerTarget, 'forms--select-ui')
    gmapController.connect()
    selectUiController.connect()
  }

  /**
   * Change and filter vendor select options depending on the service selected
   * @param {HTMLEvent} event
   * @return {void}
   */
  serviceSelectChange(event) {
    // Update vendors from service
    if (!event.target.value) return
    this.diplayServiceFormTypeFields(event.target.value)
    // Update vendor cat from guest app services
    if (this.categorySelectTarget.value === '' || this.categorySelectTarget.value === null) {
      fetch(`/api/v1/find_vendor_cat_by_guest_app_service?service_id=${event.target.value}`)
        .then(response => response.json())
        .then(data => {
          if (data) {
            this.categorySelectTarget.tomselect.setValue(data.id)
          }
        })
    }
  }

  displayFavoriteVendors(serviceId, clearVendor = true) {
    if (!serviceId) {
      return this.dynamicFields(null)
    }
    fetch(`/api/v1/find_vendors_by_services?service_id=${serviceId}`)
      .then(response => response.json())
      .then(data => {
        if (!data.length) return
        if (this.hasVendorSelectTarget) {
          const vendorSelect = this.vendorSelectTarget.tomselect
          const lastOptions = Object.values(vendorSelect.options).map(option => {
            return { value: option.value, text: option.text, group: 'other' }
          })
          if (clearVendor) vendorSelect.clear(true)
          vendorSelect.clearOptions()

          const favorite = I18n === 'en' ? 'Favourite vendors' : 'Prestataires favoris'
          const other = I18n === 'en' ? 'Other vendors' : 'Autres prestataires'

          vendorSelect.addOptionGroup('favorite', { $order: 1, name: favorite })
          vendorSelect.addOptionGroup('other', { $order: 2, name: other })

          data.forEach(vendor => vendorSelect.addOption({ value: vendor.id, text: vendor.name, group: 'favorite' }))
          vendorSelect.addOptions(lastOptions)
          vendorSelect.refreshOptions(false)
        }
      })
    this.reloadDisplayBoxVisu()
  }

  /**
   * Display the box visu after reloading the form
   * @return {void}
   */
  reloadDisplayBoxVisu() {
    fadeIn(document.querySelector('.js-form-modal__widgets-bar'))
    const partyInput = document.getElementById('booking_party_id')
    const guestInput = document.getElementById('select-input-value')
    const vendorInput = document.getElementById('booking_vendor_id')
    if (partyInput.tomselect.getValue() !== '') {
      partyInput.tomselect.trigger('change', partyInput.tomselect.getValue())
    } else if (guestInput.value !== '') {
      show(document.querySelector('.js-form-boxvisu-guest'))
      const hotelSlug = document.querySelector('.booking-form, .instruction-form').dataset.hotelId
      const myHeaders = {
        headers: {
          Accept: '*/*',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      }
      fetch(`/hotels/${hotelSlug}/service/guests/${guestInput.value}/form_boxvisu`, myHeaders)
        .then(res => res.text())
        .then(data => {
          eval(data)
        })
    }
    if (vendorInput.tomselect.getValue() !== '') {
      vendorInput.tomselect.trigger('change', vendorInput.tomselect.getValue())
    }
  }

  /**
   * Display the form dynamic fields depending on the service selected and the service form type
   * @param {number} serviceId
   * @return {void}
   */
  diplayServiceFormTypeFields(serviceId) {
    Booking.form(serviceId, null).then(data => {
      if (!data) return
      this.dynamicFormBlockTarget.innerHTML = data.html_form
      this.formDocumentChange()
    })
    this.displayFavoriteVendors(serviceId)
  }

  /**
   * Reset the dynamic fields to hide them all
   */
  resetDynamicFields() {
    if (this.hasTransportationInputsTarget) this.transportationInputsTarget.classList.add('d-none')
    if (this.hasFlightTransportInputsTarget) this.flightTransportInputsTarget.classList.add('d-none')
    this.skiPassInputsTarget.classList.add('d-none')
    this.skiRentalInputsTarget.classList.add('d-none')
    this.endDateTarget.classList.add('d-none')
  }

  /**
   * @param {HTMLEvent} event
   */
  toggleRadiobtn(event) {
    if (event.target.checked) {
      event.target.closest('.form__radio-model').classList.add('form-radio--checked')
      this[`${event.params.inputToggle}Target`]?.classList.remove('d-none')
    } else {
      event.target.closest('.form__radio-model').classList.remove('form-radio--checked')
      this[`${event.params.inputToggle}Target`]?.classList.add('d-none')
    }
  }

  /**
   * Toggle the guest and party input depending on the radio button selected
   *
   * @param {HTMLEvent} event
   */
  toggleGuestAndParty(event) {
    if (event.target.value === 'guest') {
      this.radioPartyTarget.classList.remove('form-radio--checked')
      this.radioGuestTarget.classList.add('form-radio--checked')
      this.partyInputTarget.classList.add('d-none')
      this.guestInputTarget.classList.remove('d-none')
      this.addPersonTargets.forEach(input => {
        input.classList.remove('d-none')
      })
    } else {
      this.radioPartyTarget.classList.add('form-radio--checked')
      this.radioGuestTarget.classList.remove('form-radio--checked')
      this.partyInputTarget.classList.remove('d-none')
      this.guestInputTarget.classList.add('d-none')
      this.addPersonTargets.forEach(input => {
        input.classList.add('d-none')
      })
      this.newGuestTarget.classList.add('tw-hidden')
    }
  }

  /**
   *  Display the confirmed with input if the payment choice is done or reconfirmed
   *
   * @param {HTMLEvent} event
   */
  paymentChoiceChange(event) {
    if (['done', 'reconfirmed'].includes(event.target.value)) {
      return this.confirmedWithContainerTarget.classList.remove('d-none')
    }
    this.confirmedWithContainerTarget.classList.add('d-none')
    this.confirmedWithInputTarget.value = ''
  }

  /**
   *  Display the dynamic fields depending on the category selected
   * @param {string} category
   * @return {void}
   */
  dynamicFields(category) {
    if (this.hasServiceSelectTarget && this.serviceSelectTarget.value && !this.hasCategorySelectTarget) return

    if (this.hasTransportationInputsTarget) {
      this.transportationInputsTarget.classList.add('d-none')
    }
    if (this.hasTransportationInputsTarget) {
      VendorCategory.find(this.categorySelectTarget.tomselect.getValue()).then(data => {
        if (data.form_type === 'transportation') {
          if (this.hasTransportationInputsTarget) {
            this.transportationInputsTarget.classList.remove('d-none')
          }
        }
      })
    }
  }

  emptySelectize(e) {
    const containerForm = e.currentTarget.closest('#newPerson')
    const searchGuest = containerForm.querySelector('.selectized')
    searchGuest.selectize.clear()
    this.stayInputTarget.tomselect.clear()
    document.querySelector('.js-form-modal__widgets-bar').style.display = 'none'
  }

  hideStayInput() {
    const stayInputContainer = this.stayInputTarget.closest('#booking-form-stays-container')
    stayInputContainer.classList.remove('d-none')
    this.stayInputTarget.tomselect.clear()
    this.stayInputTarget.tomselect.clearOptions()
    stayInputContainer.classList.add('d-none')
  }

  /**
   * Display stay or project inputs depending on the guest selected and the hotel mode
   * @param {number} guestId
   * @return {void}
   */
  guestChange(guestId, noVendorCommission = false, noAlert = false) {
    if (guestId == '') return this.hideStayInput()
    this.loadStayOnGuestChange(guestId).then(() => {
      Guest.find(guestId).then(data => {
        this.#guest = data
        if (!noVendorCommission) this.setVendorCommission(noAlert)
      })
    })
    this.startDateChange()
  }

  /**
   *  Load the stays of the guest selected and display them in the stay input
   *
   * @param {number} guestId
   * @return {void}
   */
  async loadStayOnGuestChange(guestId) {
    if (!this.hasStayInputTarget) return
    if (!guestId) {
      this.stayInputTarget.tomselect.clear()
      this.stayInputTarget.tomselect.clearOptions()
      return
    }
    const stayInputContainer = this.stayInputTarget.closest('#booking-form-stays-container')
    const currentStays = await fetch(`/api/v1/stays?id=${guestId}`).then(res => res.json())
    const currentProjects = await fetch(`/api/v1/client_projects?id=${guestId}`).then(res => res.json())
    if (currentStays.length > 0 || currentProjects.length > 0) {
      stayInputContainer.classList.remove('d-none')
      this.stayInputTarget.tomselect.clear()
      this.stayInputTarget.tomselect.clearOptions()
      let options = []
      currentStays.forEach(stay => {
        options.push({ value: `stay_${stay.id}`, text: stay.dates, date: new Date(stay.start_date) })
      })
      currentProjects.forEach(project => {
        options.push({ value: `project_${project.id}`, text: project.dates_with_title, date: new Date(project.start_date) })
      })

      options = options.sort((a, b) => b.date - a.date)
      options.forEach(option => {
        const { date, ...cleanOption } = option
        this.stayInputTarget.tomselect.addOption(cleanOption)
      })
      this.startDateChange()
    } else stayInputContainer.classList.add('d-none')
  }

  stayOrProjectChanges(e) {
    const stayInput = document.querySelector('#stayHidden')
    const projectInput = document.querySelector('#projectHidden')
    if (e.target.value == null || e.target.value == '') {
      stayInput.value = ''
      projectInput.value = ''
      return
    }
    const regex = /(\w+)_(\d+)/
    const matches = e.target.value.match(regex)

    if (!matches) return

    const name = matches[1]
    const id = matches[2]
    if (name === 'stay') {
      let input = stayInput
      input.value = id
      projectInput.value = ''
    } else {
      let input = projectInput
      input.value = id
      stayInput.value = ''
    }
  }

  /**
   *  Load the terms and conditions of the vendor selected and display them in the booking terms and condition input
   *
   * @param {number} vendorID
   */
  loadTermsAndConditionsOnVendorChange(vendorId) {
    if (this.hasBookingTermsAndConditionsEnTarget && this.hasBookingTermsAndConditionsFrTarget) {
      const bookingTermsAndConditionsEn = this.bookingTermsAndConditionsEnTarget
      const bookingTermsAndConditionsFr = this.bookingTermsAndConditionsFrTarget
      if (bookingTermsAndConditionsEn.value === '' || bookingTermsAndConditionsFr.value === '') {
        $.ajax({
          type: 'GET',
          data: 'id=' + vendorId,
          url: `/api/v1/find_vendor_tac`,
          success: function (data) {
            if (data[0].text_en.length > 0 && bookingTermsAndConditionsEn.value === '') {
              bookingTermsAndConditionsEn.value = data[0].text_en
            }
            if (data[0].text_fr.length > 0 && bookingTermsAndConditionsFr.value === '') {
              bookingTermsAndConditionsFr.value = data[0].text_fr
            }
          }
        })
      }
    }
  }

  isNewAction() {
    return document.querySelector('#booking_id').value === ''
  }
}
