import Kitsu from 'kitsu'

const kitsuApi = new Kitsu({
  baseURL: '/api'
})

async function apiFetch(endpoint, options) {
  try {
    const response = await kitsuApi.get(endpoint, options)
    return response.data
  } catch (error) {
    throw new Error(`Fetch error: ${error.message}`)
  }
}

export { apiFetch }
