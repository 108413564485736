import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { min: Number, max: Number }

  validate(event) {
    const input = event.currentTarget
    if (input) {
      const inputValue = parseInt(input.value)
      if (isNaN(inputValue)) {
        input.value = this.hasMinValue ? this.minValue : ''
      } else if (this.hasMinValue && inputValue < this.minValue) {
        input.value = this.minValue
      } else if (this.hasMaxValue && inputValue > this.maxValue) {
        input.value = this.maxValue
      } else {
        input.value = inputValue // force decimal to integer
      }
    }
  }
}
