import { Controller } from 'stimulus'

export default class extends Controller {
  handleDelete(_event) {
    const target = _event.target
    const itineraryId = target.dataset.id
    target.parentElement.lastElementChild.removeAttribute('hidden')
    target.setAttribute('hidden', 'true')
    fetch(`/itineraries/${itineraryId}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .finally(() => {
        document.querySelector(`#itinerary_${itineraryId}`).remove()
      })
      .catch(error => {
        console.error('Error:', error)
        window.location.reload()
      })
  }
}
