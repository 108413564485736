import Lightbox from 'stimulus-lightbox'

export default class extends Lightbox {
  connect() {
    super.connect()

    // Get the lightgallery instance
    this.lightGallery

    // Default options for every lightboxes.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      // Your default options here
      addClass: 'lightbox-container',
      counter: false,
      download: false,
      enableDrag: false,
      enableSwipe: true,
      grab: false,
      iframeMaxWidth: '720px',
      nextHtml: '<i class="fa-solid fa-chevron-right"></i>',
      prevHtml: '<i class="fa-solid fa-chevron-left"></i>',
      mobileSettings: {
        showCloseIcon: true
      }
    }
  }

  removeArrowOnlyOnePic() {
    const rowPics = document.querySelectorAll('.row-pics')
    const lightBoxContainer = document.querySelectorAll('.lightbox-container')
    rowPics.forEach((row, index) => {
      const rowItems = row.querySelectorAll('.checklist-pic')
      const currentLightBox = lightBoxContainer[index]
      if (rowItems.length === 1) {
        currentLightBox.querySelector('.lg-prev').classList.add('d-none')
        currentLightBox.querySelector('.lg-next').classList.add('d-none')
      }
    })
  }
}
