import Dropdown from 'stimulus-dropdown'

export default class extends Dropdown {
  static targets = ['menu', 'input']
  connect() {
    super.connect()
  }

  toggle(event) {
    this.menuTarget.classList.contains('tw-hidden') && this.menuTarget.classList.remove('tw-hidden')
    super.toggle()
  }

  hide(event) {
    super.hide(event)
  }
}

// export default class extends Dropdown {
//   WHITELIST = ['flatpickr-month', 'flatpickr-months', 'flatpickr-current-month']
//   connect() {
//     super.connect()
//   }

//   toggle(event) {
//     super.toggle()
//   }

//   hide(event) {
//     if (this.whiteList(event)) return
//     super.hide(event)
//   }

//   whiteList(event) {
//     const classList =  event.target.parentNode.parentNode.classList
//     console.log("EVENT TARGET", event.target);
//     console.log("CLASS", classList)
//     this.WHITELIST.forEach(className => {
//       if (classList.contains(className)) {
//         return false
//       }
//     })
//     return true
//   }
// }
