import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['phoneInput']

  itl = null
  connect() {
    this.loadPhoneNumberInput()
  }

  loadPhoneNumberInput() {
    let allPhoneInputs = this.phoneInputTargets

    allPhoneInputs.forEach(input => {
      this.loadSingleInput(input)
    })
  }

  loadSingleInput(input) {
    const { country } = input.dataset
    this.itl = window.intlTelInput(input, {
      nationalMode: true,
      autoPlaceholder: 'off',
      initialCountry: country,
      // Don't know how to get the utils.js in node_modules folder
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.2/js/utils.js?1549804213570' // path for placeholder and formatting only, no validation on front end
    })
  }

  submitForm() {
    this.phoneInputTargets.forEach(input => {
      input.value = this.itl.getNumber()
    })
  }
}
