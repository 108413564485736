import Kitsu from 'kitsu'

export default class Client {
  constructor(resource) {
    this.api = new Kitsu({
      baseURL: '/api'
    })
    this.resource = resource
  }

  async findAll(filters = {}) {
    try {
      const response = await this.api.get(this.resource, {
        params: {
          filter: {
            ...filters
          }
        }
      })
      return response.data
    } catch (error) {
      console.error('Error fetching all data:', error)
      throw error
    }
  }

  // Méthode pour effectuer une requête GET
  async find(id) {
    try {
      const response = await this.api.get(id ? `${this.resource}/${id}` : '')
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error)
      throw error
    }
  }

  // Méthode pour effectuer une requête POST
  async create(data) {
    try {
      const response = await this.api.post(this.resource, data)
      return response.data
    } catch (error) {
      console.error('Error creating data:', error)
      throw error
    }
  }

  // Méthode pour effectuer une requête PATCH
  async update(data) {
    try {
      const response = await this.api.patch(this.resource, data)
      return response.data
    } catch (error) {
      console.error('Error updating data:', error)
      throw error
    }
  }

  // Méthode pour effectuer une requête DELETE
  async delete(id) {
    try {
      const response = await this.api.delete(`${this.resource}/${id}`)
      return response.data
    } catch (error) {
      console.error('Error deleting data:', error)
      throw error
    }
  }
}
