import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'menu']

  toggle(event) {
    this.menuTarget.classList.toggle('tw-hidden')
    this.dropdownTarget.classList.toggle('tw-border-purple')
  }

  hide(event) {
    this.menuTarget.classList.add('tw-hidden')
    this.dropdownTarget.classList.remove('tw-border-purple')
  }
}
