import { Controller } from 'stimulus'
import { apiFetch } from '../../api/jana/apiWrapper'

export default class extends Controller {
  static outlets = ['stays--form']
  static targets = [
    'totalPrice',
    'touristTax',
    'commission',
    'bankCharges'
  ]

  staysFormOutletConnected(outlet, element) {
    this.totalPriceTarget.value = outlet.totalPriceTarget.value
    this.touristTaxTarget.value = outlet.touristTaxTarget.value
    this.commissionTarget.value = outlet.serviceChargeTarget.value
    this.bankChargesTarget.value = outlet.bankChargesTarget.value
  }

  calculateHostPayment() {
    let totalPrice = parseFloat(this.totalPriceTarget.value)
    let touristTax = parseFloat(this.touristTaxTarget.value)
    let commission = parseFloat(this.commissionTarget.value)
    let bankCharges = parseFloat(this.bankChargesTarget.value) || 0

    let hostPayment = totalPrice - touristTax - commission - bankCharges

    if (!isNaN(hostPayment)) {
      this.staysFormOutlet.totalPriceTarget.value = totalPrice
      this.staysFormOutlet.touristTaxTarget.value = touristTax
      this.staysFormOutlet.serviceChargeTarget.value = commission
      this.staysFormOutlet.bankChargesTarget.value = bankCharges

      this.staysFormOutlet.hostPaymentTarget.value = hostPayment.toFixed(2).replace('.', ',')
      this.staysFormOutlet.computeCommissionableAmount()
    }
  }
}
