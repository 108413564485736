import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['channelManagerOption', 'arrivalPreferences']

  toggleBnbFields(e) {
    const modeBnb = e.target.value === 'bnb'
    this.channelManagerOptionTarget.disabled = !modeBnb
    modeBnb ? this.arrivalPreferencesTarget.classList.remove('d-none') : this.arrivalPreferencesTarget.classList.add('d-none')
  }
}
