// app/javascript/controllers/switch_toggle_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleContent', 'input', 'cancelButton', 'saveButton', 'form']

  connect() {
    this.checkedBoxesFilter = []
    this.updateInput()

    this.initEventListeners()
  }

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()
    const content = this.toggleContentTarget
    if (content.classList.contains('invoicing-filters-dropdowns-active')) {
      content.classList.remove('invoicing-filters-dropdowns-active')
    } else {
      content.classList.add('invoicing-filters-dropdowns-active')
    }
    this.updateInput()
  }

  cancel(event) {
    event.stopPropagation()
    event.preventDefault()
    const count = this.toggleContentTarget.querySelectorAll("input[type='checkbox']:checked").length
    if (count) {
      this.checkedBoxesFilter = []
      this.clearCheckedBoxes()
      this.updateInput()
    } else {
      this.toggleContentTarget.classList.add('invoicing-filters-dropdowns-active')
    }
    this.formTarget.submit()
  }

  save(event) {
    event.preventDefault()
    console.log(this.formTarget)
    this.formTarget.submit()
    this.toggleContentTarget.classList.remove('invoicing-filters-dropdowns-active')
  }

  updateInput() {
    const input = this.inputTarget
    const count = this.toggleContentTarget.querySelectorAll("input[type='checkbox']:checked").length
    if (count) {
      input.value = `${count} ${input.placeholder}`
      input.classList.add('active')
      this.cancelButtonTarget.querySelector('.x-icon').classList.remove('tw-hidden')
    } else {
      input.value = ''
      input.classList.remove('active')
      this.cancelButtonTarget.querySelector('.x-icon').classList.add('tw-hidden')
    }
  }

  clearCheckedBoxes() {
    this.toggleContentTarget.querySelectorAll("input[type='checkbox']:checked").forEach(checkbox => checkbox.click())
  }

  initEventListeners() {
    document.addEventListener('click', event => {
      if (!this.element.contains(event.target)) {
        this.toggleContentTargets.forEach(content => {
          content.classList.remove('invoicing-filters-dropdowns-active')
        })
      }
    })
  }
}
