import { Controller } from 'stimulus'
import EditorJS from '@editorjs/editorjs'
import Underline from '@editorjs/underline'
import List from '@editorjs/list'
import Paragraph from '@editorjs/paragraph'
import { MergeTag } from './editorJs/mergeTag'

export default class extends Controller {
  static targets = ['editorFr', 'editorEn', 'inputFr', 'inputEn']

  connect() {
    const editorFr = this.newEditor(this.editorFrTarget, this.inputFrTarget)
    const editorEn = this.newEditor(this.editorEnTarget, this.inputEnTarget)
    this.editorEnTarget.style.display = 'none'
    this.editors = { en: editorEn, fr: editorFr }
    this.currentEditor = editorFr
    this.currentInput = this.inputFrTarget
    this.currentLang = 'fr'
  }

  newEditor(target, input) {
    const data = JSON.parse(input.value)
    const editor = new EditorJS({
      holder: target,
      data,
      tools: {
        mergeTag: { class: MergeTag },
        paragraph: {
          class: Paragraph
        },
        underline: Underline,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        }
      },
      onChange: (api, event) => {
        this.save(editor, input)
      }
    })
    return editor
  }

  save(editor, input) {
    editor.save().then(outputData => {
      input.value = JSON.stringify(outputData)
    })
  }

  toggleLanguage() {
    if (this.editorFrTarget.style.display === 'none') {
      this.editorFrTarget.style.display = 'block'
      this.editorEnTarget.style.display = 'none'
      this.currentEditor = this.editors.fr
      this.currentInput = this.inputFrTarget
      this.currentLang = 'fr'
    } else {
      this.editorEnTarget.style.display = 'block'
      this.editorFrTarget.style.display = 'none'
      this.currentEditor = this.editors.en
      this.currentInput = this.inputEnTarget
      this.currentLang = 'en'
    }
  }

  displayMergeTag(e) {
    const sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      const range = sel.getRangeAt(0)
      if (range.startContainer?.parentElement?.classList?.contains('vendor_category_merge_tag')) {
        range.startContainer.parentElement.remove()
      }
      range.insertNode(this.mergeTag(e.target.value))
    }
    this.save(this.currentEditor, this.currentInput)
    e.target.options[0].selected = true
  }

  mergeTag(name) {
    let mergetag = document.createElement('span')
    mergetag.classList.add('vendor_category_merge_tag')
    mergetag.setAttribute('contenteditable', 'false')
    mergetag.innerText = name
    if (
      ['travel_info', 'number_luggages', 'number_pers', 'terms_and_conditions', 'breakfast', 'passengers', 'ski_pass_list', 'meal'].includes(name)
    ) {
      mergetag.innerText += `_${this.currentLang}`
    }
    return mergetag
  }
}
