import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['errorContainer', 'selectCurrentDates', 'selectCurrentHousing']
  connect() {
    if (window.location.search !== '') {
      let params = window.location.search
      this.disabledSelect()
    }

    if (this.hasErrorContainerTarget) {
      this.errorContainerTarget.scrollIntoView({ behavior: 'smooth' })
    }
  }

  disabledSelect() {
    this.selectCurrentHousingTarget.classList.add('is-disabled')
  }
}
