import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'inputEmail', 'inputName', 'inputPhone', 'inputSearchGmaps']

  inputsGmapsAutComplete = []
  autocomplete = null

  initialize() {
    this.onPlaceChanged = this.onPlaceChanged.bind(this)
  }

  connect() {
    this.initGmaps()
  }

  initGmaps() {
    const inputSearch = this.inputSearchGmapsTarget
    this.autocomplete = new google.maps.places.Autocomplete(inputSearch, {
      types: [inputSearch.getAttribute('data-input-gmaps-type')]
    })

    this.autocomplete.addListener('place_changed', () => this.onPlaceChanged())
    this.inputTargets.forEach(input => {
      google.maps.event.addDomListener(input, 'keydown', e => {
        e.key === 'Enter' && e.preventDefault()
      })
    })
  }

  onPlaceChanged() {
    const place = this.autocomplete.getPlace()
    if (place === undefined && this.inputSearchGmapsTarget.value) return (this.inputNameTarget.value = this.inputSearchGmapsTarget.value)

    this.presence(this.inputNameTarget, place.name)
    this.presence(this.inputEmailTarget, place.formatted_address)
    this.presence(this.inputPhoneTarget, place.international_phone_number)
    const placePhotoIsUndefined = typeof place.photos !== 'undefined'
    const imgUrl = placePhotoIsUndefined && place.photos[0].getUrl({ width: 960, height: 280 })
    const gaPhoto = document.querySelector('#ga_google_photo')
    const itineraryPhoto = document.querySelector('#itinerary_google_photo')
    const imgDivs = document.querySelectorAll('.form__input-photo-preview')
    if (imgUrl) {
      imgDivs.forEach(imgDiv => {
        imgDiv.style.backgroundImage = `url(${imgUrl})`
        imgDiv.classList.add('--edit-mode')
      })
      gaPhoto.value = imgUrl
      itineraryPhoto.value = imgUrl
    } else {
      imgDivs.forEach(imgDiv => {
        imgDiv.style.backgroundImage = 'url()'
        imgDiv.classList.remove('--edit-mode')
      })
      gaPhoto.value = ''
      itineraryPhoto.value = ''
    }
  }

  presence(input, value) {
    typeof value === 'undefined' ? (input.value = '') : (input.value = value)
  }
}
