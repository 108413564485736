import { Controller } from 'stimulus'
import SelectUIController from '../forms/select_ui_controller.js'

export default class extends Controller {
  static targets = [
    'passengerDefaultForm',
    'passengersFormsContainer',
    'passengerForms',
    'passengersCardsComponent',
    'passengerNewAction',
    'selectOne'
  ]

  counter = this.passengerDefaultFormTarget.dataset.passengersCounter

  currentModalData = []
  /**
   * create a new passenger form and open it in a modal
   * @param {HTMLElement} event - The event element clicked on.
   */

  scrollManagementOnOpen(event) {
    const openedForm = document.querySelector('.form-modal__content--open')
    openedForm.style.overflow = 'hidden'
  }

  scrollManagementOnClose(event) {
    const openedForm = document.querySelector('.form-modal__content--open')
    openedForm.style.overflow = 'auto'
  }

  newForm(event) {
    this.scrollManagementOnOpen(event)
    event.target.style.setProperty('pointer-events', 'none')
    const clone = this.passengerDefaultFormTarget.cloneNode(true)
    const scrollTopValue = document.querySelector('.form-modal__content--open').scrollTop
    // Remove the defaultForm data target so there's still be one unique default form
    clone.removeAttribute('data-bookings--passengers-form-target')
    event.target.closest('#new-passenger-wrapper').insertAdjacentElement('beforebegin', clone)
    // initialize tom-select on the new form
    clone.querySelector('.flight_ranking_input').setAttribute('data-bookings--passengers-form-target', 'selectOne')
    this.loadSelectOneTargets()
    this.passengersFormsContainerTarget.querySelector(`#${clone.id}`).classList.remove('d-none')
    this.passengersFormsContainerTarget.querySelector(`#${clone.id}`).style.top = scrollTopValue + 'px'
  }

  loadSelectOneTargets() {
    new SelectUIController().loadSelectOneTargets(this.selectOneTargets)
  }

  /**
   * open the modal form on edit passenger action
   * @param {HTMLElement} event - The event element clicked on.
   */
  openModalForm(event) {
    this.scrollManagementOnOpen(event)
    const passengerForm = event.target.closest('div.passenger-card-component').nextElementSibling
    const passengerSelectInput = passengerForm.querySelector('select[data-bookings--passengers-form-target]')
    this.storeValues(passengerForm.querySelectorAll('input, select'))
    if (
      passengerSelectInput === null ||
      (passengerSelectInput !== null && passengerSelectInput.dataset['bookings-PassengersFormTarget'].includes('selectOne') === false)
    ) {
      passengerForm.querySelector('.flight_ranking_input').setAttribute('data-bookings--passengers-form-target', 'selectOne')
      this.loadSelectOneTargets()
    }
    const scrollTopValue = document.querySelector('.form-modal__content--open').scrollTop
    passengerForm.classList.remove('d-none')
    passengerForm.style.top = scrollTopValue + 'px'
  }

  storeValues(inputsElements) {
    this.currentModalData = []
    inputsElements.forEach(input => {
      if (input.dataset.attribute === 'id') return
      if (input.dataset.attribute === undefined) return
      if (input.type === 'radio') {
        this.currentModalData.push({ key: input.value, value: input.checked })
      } else if (input.type === 'checkbox') {
        this.currentModalData.push({ key: input.dataset.attribute, value: input.checked })
      } else {
        this.currentModalData.push({ key: input.dataset.attribute, value: input.value })
      }
    })
  }

  /**
   * close the modal form when clicking on cancel or after validate passenger form action
   * @param {HTMLElement} event - The event element clicked on.
   */
  closeModalForm(event) {
    this.scrollManagementOnClose(event)
    event.target.closest('div.passenger-form-wrapper').classList.add('d-none')
    this.passengerNewActionTarget.style.removeProperty('pointer-events')
  }

  /**
   * Check if the action is an edit
   * By checking if the passenger card exist or not
   * @param {HTMLElement} event - The event element clicked on.
   */
  checkEditAction(event) {
    const passengerFormNumber = event.target.closest('div.passenger-form-wrapper').dataset.passengerNumber
    let passengerCardExist = false

    this.passengersCardsComponentTargets.forEach(card => {
      const passengerCardNumber = card.dataset.passengerNumber
      if (passengerCardNumber === passengerFormNumber) {
        passengerCardExist = true
      }
    })

    return passengerCardExist
  }

  /**
   * Happened when clicking on validate button of the passenger form
   * @param {HTMLElement} event - The event element clicked on.
   */
  validate(event) {
    this.runValidation(event)
    if (this.checkErrors(event) === true) return

    if (this.checkEditAction(event) === false) {
      this.insertPassengerComponentPartial(event)
      this.incrementDefaultFormId()
    } else {
      const passengerFormNumber = event.target.closest('div.passenger-form-wrapper').dataset.passengerNumber
      this.refreshFullname(event, passengerFormNumber)
    }

    this.closeModalForm(event)
  }

  /**
   * Update the passenger card component partial with the new passenger full name after edit
   * @param {HTMLElement} event - The event element clicked on.
   * @param {number} passengerFormNumber - The passenger form id.
   */
  refreshFullname(event, passengerFormNumber) {
    const data = this.getPassengerData(event)
    const passengerCard = this.passengersCardsComponentTargets.find(card => card.dataset.passengerNumber === passengerFormNumber)
    passengerCard.querySelector('p.passenger-fullname').innerHTML = data.passengerFullName
  }

  /**
   * Cancel the form passenger when clicked on cancel button
   * then delete the form passenger if it's a new passenger form action
   * @param {HTMLElement} event - The event element clicked on.
   */
  cancel(event) {
    this.closeModalForm(event)
    if (this.checkEditAction(event) === false) this.deleteForm(event)
    const form = event.target.closest('div.passenger-form-wrapper')

    this.currentModalData.forEach(field => {
      const input = form.querySelector(`[data-attribute=${field.key}]`)
      if (field.value === true || field.value === false) {
        form.querySelector(`[value=${field.key}]`).checked = field.value
      } else if (field.key === 'flight_ranking') {
        input.value = field.value
        input.tomselect.setValue(field.value)
      } else {
        input.value = field.value
      }
      input?.classList?.remove('form-field--error')
      input.closest('.form__field-content')?.querySelector('span.passenger-error-validation')?.classList?.add('d-none')
    })
  }

  /**
   * Increment the default passenger form id
   * Increment the id of inputs/selects of the default passenger form
   */
  incrementDefaultFormId() {
    this.counter++
    const defaultForm = this.passengerDefaultFormTarget
    defaultForm.setAttribute('id', `booking-passenger-forms-${this.counter}`)
    defaultForm.setAttribute('data-passenger-number', `passenger-${this.counter}`)
    defaultForm.querySelector('.passengerFormFields').dataset.index = this.counter
    const inputs = [...defaultForm.getElementsByTagName('input')]
    const selects = [...defaultForm.getElementsByTagName('select')]

    inputs.forEach(element => {
      if (element.dataset.attribute) {
        this.setAttributes(element, {
          id: `booking_booking_passengers_attributes_${this.counter}_${element.dataset.attribute}`,
          name: `booking[booking_passengers_attributes][${this.counter}][${element.dataset.attribute}]`
        })
        if (element.dataset.choice) {
          this.setAttributes(element, {
            id: `booking_booking_passengers_attributes_${this.counter}_${element.dataset.attribute}_${element.dataset.choice}`
          })
          this.setAttributes(element.nextElementSibling, {
            for: `booking_booking_passengers_attributes_${this.counter}_${element.dataset.attribute}_${element.dataset.choice}`
          })
        } else if (element.dataset.label) {
          this.setAttributes(element.nextElementSibling, {
            for: `booking_booking_passengers_attributes_${this.counter}_${element.dataset.attribute}`
          })
        }
      }
    })

    selects.forEach(element => {
      if (element.dataset.attribute) {
        this.setAttributes(element, {
          id: `booking_booking_passengers_attributes_${this.counter}_${element.dataset.attribute}`,
          name: `booking[booking_passengers_attributes][${this.counter}][${element.dataset.attribute}]`
        })
      }
    })
  }

  /**
   * Get the passenger full name and index data
   * @param {HTMLElement} event - The event element clicked on.
   */
  getPassengerData(event) {
    const data = { passengerFullName: '', passengerId: '', index: '' }
    const inputs = [...event.target.closest('.passengerFormFields').getElementsByTagName('input')]
    inputs.forEach(element => {
      if (element.dataset.attribute === 'id' && element.value) data.passengerId = element.value
      if (element.dataset.attribute === 'first_name' && element.value) data.passengerFullName += `${element.value} `
      if (element.dataset.attribute === 'last_name' && element.value) data.passengerFullName += `${element.value}`
    })
    data.index = event.target.closest('.passengerFormFields').dataset.index
    return data
  }

  /**
   * Insert the passenger component partial before the passenger form
   * @param {HTMLElement} event - The event element clicked on.
   */
  insertPassengerComponentPartial(event) {
    const data = this.getPassengerData(event)
    const form = event.target.closest('.passenger-form-wrapper')
    fetch(`/api/booking_passenger/passenger_card_component/${data.passengerFullName}/${data.index}`)
      .then(response => response.json())
      .then(data => {
        form.insertAdjacentHTML('beforebegin', data.partial)
      })
  }

  /**
   * Delete the form passenger when cancel button is clicked on new passenger action
   * @param {HTMLElement} event - The event element clicked on.
   */
  deleteForm(event) {
    event.target.closest('div.passenger-form-wrapper').remove()
  }

  /**
   * Delete the BookingPassenger Objet
   * @param {HTMLElement} event - The event element clicked on.
   */
  deleteBookingPassengerObject(event) {
    const passengerId = event.target.parentNode.dataset.passengerId
    if (passengerId !== undefined || passengerId !== '') {
      fetch(`/api/booking_passengers/${passengerId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
    }
  }

  /**
   * Delete the passenger component partial with his form when clicked on delete cross button on component
   * Delete the BookingPassenger Objet
   * @param {HTMLElement} event - The event element clicked on.
   */
  deletePassenger(event) {
    const response = confirm(`${event.target.closest('span.delete-button').dataset.deleteMessage}`)
    if (response === false) return
    const component = event.target.closest('div.passenger-card-component')
    const form = component.nextElementSibling
    this.deleteBookingPassengerObject(event)
    component.remove()
    form.remove()
  }

  /**
   * Check if there's any errors in the form and return true if there's any
   * @param {HTMLElement} event - The event element clicked on.
   */
  checkErrors(event) {
    const inputs = [...event.target.closest('.passengerFormFields').getElementsByTagName('input')]
    let errorsExist = false
    inputs.forEach(element => {
      if (element.classList.contains('form-field--error')) errorsExist = true
    })
    return errorsExist
  }

  /**
   * Run the validation on the form
   * @param {HTMLElement} event - The event element clicked on.
   */
  runValidation(event) {
    const inputs = [...event.target.closest('.passengerFormFields').getElementsByTagName('input')]
    inputs.forEach(element => {
      if (element.dataset.attribute === 'first_name' || element.dataset.attribute === 'last_name') {
        if (element.value === '') {
          element.classList.add('form-field--error')
          element.closest('.form__field-content').querySelector('span.passenger-error-validation').classList.remove('d-none')
        } else {
          element.classList.remove('form-field--error')
          element.closest('.form__field-content').querySelector('span.passenger-error-validation').classList.add('d-none')
        }
      }
    })
  }

  /**
   * Set the attributes of an element
   * @param {HTMLElement} el - The element who possess the attributes.
   * @param {HTMLElement} attrs - The attributes to set.
   */
  setAttributes(el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key])
    }
  }
}
