import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['distributeInfosHotelsAndUsers', 'distributeInfosAccountsTypes', 'distributeInfosRadios']

  markAsRead(event) {
    const target = event.currentTarget
    const hotelID = target.dataset.hotel
    const notifID = target.dataset.notif
    const targetParent = target.closest('.notification--unread')
    const url = `/hotels/${hotelID.toString()}/notifications/mark_as_read/${notifID.toString()}`
    const unreadCounters = document.querySelectorAll('.navbar-unread-notifications-badge')
    if (unreadCounters.length) {
      const unreadCountersValue = parseInt(unreadCounters[0].childNodes[1].textContent)
      if (unreadCountersValue > 1) {
        unreadCounters.forEach(counter => {
          counter.childNodes[1].textContent = unreadCountersValue - 1
        })
      } else {
        unreadCounters.forEach(counter => {
          counter.remove()
        })
      }
    }
    if (target) {
      target.classList.remove('notification-unread')
    }
    if (targetParent) {
      targetParent.classList.remove('notification--unread')
    }
    $('.notifs-wrapper').hide()
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
  }

  distributeInfosAllUsers(event) {
    const currentTarget = event.currentTarget
    this.distributeInfosRadiosTargets.forEach(
      radio => radio.classList.contains('form-radio--checked') && radio.classList.remove('form-radio--checked')
    )
    currentTarget.classList.add('form-radio--checked')
    if (currentTarget.querySelectorAll('#send_to_all_users_yes').length) {
      this.distributeInfosAccountsTypesTarget.classList.remove('d-none')
      this.distributeInfosHotelsAndUsersTargets.forEach(input => input.classList.add('d-none'))
    } else {
      this.distributeInfosAccountsTypesTarget.classList.add('d-none')
      this.distributeInfosHotelsAndUsersTargets.forEach(input => input.classList.remove('d-none'))
    }
  }
}
