import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = [
    'selectOne',
    'selectOneIncludeBlank',
    'selectOneCreate',
    'selectOneGroup',
    'selectMultiple',
    'selectMultipleCreate',
    'selectMultipleDraggable',
    'selectGuestRemote'
  ]

  selects = []

  connect() {
    this.initializeSelectOneTargets()
    this.initializeSelectOneIncludeBlankTargets()
    this.initializeSelectOneCreateTargets()
    this.initializeSelectOneGroup()
    this.initializeSelectMultipleTargets()
    this.initializeSelectMultipleCreateTargets()
    this.initializeSelectMultipleDraggableTargets()
    this.initializeSelectGuestRemoteTargets()
  }

  /**
   * @param {boolean} condition
   * @param {HTMLElement} targets
   * @param {Object} options
   */
  initializeSelect(condition, targets, options = {}) {
    if (condition) {
      targets.forEach(t => {
        if (t.tomselect !== undefined) return
        this.selects.push(new TomSelect(t, options))
      })
    }
  }

  initializeSelectOneTargets(specificTargets = null) {
    let condition = this.hasSelectOneTarget
    let selectsTargets = this.selectOneTargets
    if (specificTargets) {
      condition = true
      selectsTargets = specificTargets
    }
    this.initializeSelect(condition, selectsTargets, { maxItems: 1 })
  }

  initializeSelectOneIncludeBlankTargets() {
    this.initializeSelect(this.hasSelectOneIncludeBlankTarget, this.selectOneIncludeBlankTargets, { allowEmptyOption: true })
  }

  initializeSelectOneCreateTargets() {
    this.initializeSelect(this.hasSelectOneCreateTarget, this.selectOneCreateTargets, {
      persist: true,
      create: true,
      maxItems: 1
    })
  }

  initializeSelectOneGroup() {
    this.initializeSelect(this.hasSelectOneGroupTarget, this.selectOneGroupTargets, {
      optgroupField: 'group',
      optgroupLabelField: 'name',
      lockOptgroupOrder: true
    })
  }

  initializeSelectMultipleTargets() {
    this.initializeSelect(this.hasSelectMultipleTarget, this.selectMultipleTargets, {
      plugins: ['remove_button', 'input_autogrow'],
      maxItems: 150
    })
  }

  initializeSelectMultipleCreateTargets() {
    this.initializeSelect(this.hasSelectMultipleCreateTarget, this.selectMultipleCreateTargets, {
      plugins: ['caret_position', 'input_autogrow'],
      maxItems: 150,
      create: true,
      persist: true
    })
  }

  initializeSelectMultipleDraggableTargets() {
    this.initializeSelect(this.hasSelectMultipleDraggableTarget, this.selectMultipleDraggableTargets, {
      plugins: ['remove_button', 'input_autogrow'],
      closeAfterSelect: true,
      maxItems: 150,
      delimiter: ',',
      persist: true
    })
  }

  initializeSelectGuestRemoteTargets() {
    this.initializeSelect(this.hasSelectGuestRemoteTarget, this.selectGuestRemoteTargets, {
      onInitialize: function () {
        this.on('focus', () => this.dropdown.setAttribute('hidden', ''))
      },
      load: function (query, callback) {
        if (!query.trim()) {
          callback([]) // Ne charge aucune donnée
          return
        }
        this.dropdown.removeAttribute('hidden')
        const url = '/api/guests?search=' + encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(json => {
            const options = json.map(item => ({ value: item.id, text: item.full_name }))
            self.clearOptions()
            self.addOptions(options)
            self.refreshOptions()
            callback(options)
          })
          .catch(() => callback())
      }
    })
  }

  loadSelectOneTargets(targets) {
    this.initializeSelectOneTargets(targets)
  }

  selectMultipleFilter() {
    console.log('CHANGE')
  }
}
