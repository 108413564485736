import Clipboard from '@stimulus-components/clipboard'

export default class extends Clipboard {
  connect() {
    super.connect()
  }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.innerText.trim())
    this.copied()
  }
}
