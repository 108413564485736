import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import { ManageAvailabilitiesCalendar } from '../../libs/manage_availability_calendar.js'
import { addDays, format, isEqual, parseISO, set, sub } from 'date-fns'
import { French } from 'flatpickr/dist/l10n/fr'
import Client from '../../api/jana/client.js'

export default class extends Controller {
  static targets = ['calendarContainer', 'calendarMenuCtas', 'selectDates', 'availabilityForm', 'availableRadio', 'unavailableRadio', 'errorMessage']
  static values = { id: String, showUrl: String, hotelId: String }

  calendar = ''
  currentStays = null
  manageAvailabilities = ''
  availabilities = []
  stayClient = null
  availabilityClient = null

  allCalendarDates = []
  connect() {
    this.stayClient = new Client('stays')
    this.availabilityClient = new Client('rate-and-availabilities')
    this.initCalendar()
    this.accentColorCurrentDay()
    this.fetchStayEvent()
    this.fetchAvailabilityEvent()
    this.changeMonth()
  }

  async fetchAvailabilityEvent() {
    const current_date = new Date(this.calendar.currentData.dateProfile.activeRange.start).toISOString()
    this.availabilities = await this.availabilityClient.findAll({
      housing_id: this.idValue,
      monthly: current_date
    })
    this.allCalendarDates = [...document.querySelectorAll('[data-date]')]
    this.manageAvailabilities = new ManageAvailabilitiesCalendar(this.availabilities, {})
    this.manageAvailabilities.dispatchUnavailabilities(this.availabilities)
  }

  async fetchStayEvent() {
    this.clearAllEvents()
    const current_date = new Date(this.calendar.currentData.dateProfile.activeRange.start).toISOString()
    this.currentStays = await this.stayClient.findAll({
      housing_id: this.idValue,
      monthly: current_date
    })
    this.addStaysEvent(this.currentStays)
    this.shiftedEvents()
  }

  addStaysEvent(data) {
    data.map(stay => {
      this.calendar.addEvent({
        start: stay.startDate,
        end: stay.endDate,
        title: stay.guestCalendarName,
        sourceImageUrl: stay.sourceImageUrl,
        source: stay.source,
        id: stay.id,
        classNames: [`${stay.source}-icon`, 'event-calendar', `is-${stay.status}`],
        url: `${this.showUrlValue}/${stay.id}`,
        eventKind: 'stay'
      })
    })
  }

  clearAllEvents() {
    this.calendar.getEvents().forEach(stay => stay.remove())
  }

  initCalendar() {
    const lastDate = localStorage.getItem(`${this.idValue}_lastViewedMonth`)
    this.calendar = new Calendar(this.calendarContainerTarget, {
      editable: true,
      events: [],
      eventMinWidth: 5,
      headerToolbar: {
        start: 'prev',
        center: 'title',
        end: 'next'
      },
      displayEventTime: false,
      eventAllow: false,
      initialView: 'dayGridMonth',
      initialDate: lastDate,
      locale: `${window.I18n}`,
      plugins: [interactionPlugin, dayGridPlugin],
      selectable: true,
      selectMinDistance: 2,
      fixedWeekCount: false,
      contentHeight: 'auto',
      views: {
        dayGridMonth: {
          titleFormat: { year: 'numeric', month: 'short' }
        }
      },

      select: event => {
        this.selectDatesTarget.dataset.startDate = event.startStr
        this.selectDatesTarget.dataset.endDate = event.endStr
        this.selectDatesTarget._flatpickr.destroy()
        this.flatpickrReinit(event.startStr, event.endStr)
        let timeBeetweenStartEndDate = event.end.getTime() - event.start.getTime()
        let daysBeetweenStartEndDate = Math.round(timeBeetweenStartEndDate / (1000 * 3600 * 24))
        let calMenuCtas = this.calendarMenuCtasTarget
        daysBeetweenStartEndDate === 1 ? calMenuCtas.classList.add('is-disabled') : calMenuCtas.classList.remove('is-disabled')
        const endDateLessOneDay = new Date(event.endStr)
        endDateLessOneDay.setDate(endDateLessOneDay.getDate() - 1)
        this.manageAvailabilities.checkDateAvailableOrNot(event.startStr, endDateLessOneDay, this.allCalendarDates)
        localStorage.setItem('posX', event.jsEvent.target.getBoundingClientRect().left)
        localStorage.setItem('posY', event.jsEvent.target.getBoundingClientRect().top + window.scrollY)
        calMenuCtas.classList.remove('hidden')
        if (window.innerWidth < 767 && window.innerWidth > 576) {
          calMenuCtas.style.left = localStorage.posX - 100
        } else if (window.innerWidth < 576) {
          calMenuCtas.style.left = 80
        } else {
          calMenuCtas.style.left = localStorage.posX
        }
        calMenuCtas.style.top = localStorage.posY - calMenuCtas.offsetHeight
        let linkCreateStay = document.querySelector('#createStay')
        const url = new URL(linkCreateStay.href)
        let endDate = new Date(event.endStr)
        endDate = endDate.setDate(endDate.getDate() - 1)
        url.searchParams.set('start_date', event.startStr)
        url.searchParams.set('end_date', new Date(endDate).toISOString().split('T')[0])
        linkCreateStay.href = url.href
        localStorage.clear()
        this.storeLastDate(event.view)
      },
      eventAdd: function ({ event }) {
        const sourceImageUrl = event._def.extendedProps.sourceImageUrl
        const source = event._def.extendedProps.source
        const srcStays = [...document.querySelectorAll(`.${source}-icon`)]
        srcStays.forEach(item => {
          if (sourceImageUrl !== null) {
            const srcIcon = document.createElement('img')
            const srcBox = document.createElement('div')
            if (item.querySelectorAll('.source-box').length === 0) {
              srcBox.classList.add('source-box', 'p-0.5', `bg-${source}`)
              srcIcon.src = sourceImageUrl
              srcBox.appendChild(srcIcon)
              item.appendChild(srcBox)
            }
          }
        })
      },
      eventClick: ({ view }) => {
        this.storeLastDate(view)
      },

      windowResize: () => {
        this.calendar.render()
        if (window.innerWidth < 1280) {
          this.shiftedEvents()
        }
      }
    })
    this.calendar.render()
  }

  storeLastDate(view) {
    localStorage.setItem(`${this.idValue}_lastViewedMonth`, new Date(view.currentStart).toISOString())
  }

  accentColorCurrentDay() {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const date = new Date()
    const dayName = days[date.getDay()].substr(0, 3)
    let currentDay = document.querySelector(`.fc-day-${dayName}`)
    currentDay.classList.add('bg-primary-color')
    currentDay.querySelector('a').classList.add('text-white')
  }

  cancelMenuCtas() {
    this.calendarMenuCtasTarget.classList.add('hidden')
  }

  flatpickrReinit(startDate, endDate) {
    const locale = window.I18n === 'fr' && French
    const endDateLessOneDay = new Date(endDate)
    endDateLessOneDay.setDate(endDateLessOneDay.getDate() - 1)
    this.selectDatesTarget.parentElement.querySelector('.form-control')?.remove()

    flatpickr(this.selectDatesTarget, {
      altInput: true,
      enableTime: false,
      mode: 'range',
      locale: locale,
      defaultDate: [startDate, endDateLessOneDay],
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d'
    })
  }

  shiftedEvents() {
    let cellWidth = document.querySelector('.fc-col-header-cell').offsetWidth
    let cellMiddleWith = cellWidth / 2
    const arrayFullEvents = [...document.querySelectorAll('.fc-event')]
    const arrayFullEventsLength = arrayFullEvents.length
    for (let i = 0; i < arrayFullEventsLength; i++) {
      let eventParent = arrayFullEvents[i].closest('.fc-daygrid-event-harness')
      if (arrayFullEvents[i].classList.contains('fc-event-start') && arrayFullEvents[i].classList.contains('fc-event-end')) {
        let right = eventParent.style.right
        eventParent.style.left = cellMiddleWith
        eventParent.style.right = Number(right.substring(0, right.indexOf('px'))) + cellMiddleWith
      } else if (arrayFullEvents[i].classList.contains('fc-event-start') && !arrayFullEvents[i].classList.contains('fc-event-end')) {
        eventParent.style.left = cellMiddleWith
      } else {
        let right = eventParent.style.right
        eventParent.style.left = -cellMiddleWith
        eventParent.style.right = Number(right.substring(0, right.indexOf('px'))) + cellMiddleWith
      }
    }
  }

  changeMonth() {
    this.element.querySelectorAll('.fc-button').forEach(elem => {
      elem.addEventListener('click', e => {
        this.fetchStayEvent()
        this.fetchAvailabilityEvent()
      })
    })
  }

  closeMenuCtas() {
    if (document.querySelectorAll('.fc-highlight').length === 0) {
      this.calendarMenuCtasTarget.classList.add('hidden')
    }
  }

  async createAvailability(event) {
    event.preventDefault()

    const form = new FormData(this.availabilityFormTarget)
    const startDate = this.selectDatesTarget.dataset.startDate
    const endDate = this.selectDatesTarget.dataset.endDate
    if (!this.validateForm(form)) return
    const availabilityData = this.generateAvailabilityData(startDate, endDate, form)

    try {
      await Promise.all(
        availabilityData.map(async availability => {
          const endpoint = availability.id ? 'update' : 'create'
          await this.availabilityClient[endpoint](availability)
        })
      )
      await this.fetchAvailabilityEvent()
    } catch (error) {
      throw new Error("Erreur lors de l'envoi du formulaire:", error)
    }
  }

  validateForm(form) {
    if (form.get('rate_and_availability[reason]') === '' && !this.availableRadioTarget.checked) {
      this.errorMessageTarget.classList.remove('hidden')
      return false
    } else {
      this.errorMessageTarget.classList.add('hidden')
      const modalController = this.application.getControllerForElementAndIdentifier(this.element, 'components--ui--modal')
      modalController.closeModal()
      return true
    }
  }

  generateAvailabilityData(startDate, endDate, form) {
    const availabilityData = []
    const availabilities = this.manageAvailabilities.checkAvailability(startDate, endDate)
    let currentDate = parseISO(startDate)
    const endingDate = sub(parseISO(endDate), { days: 1 })
    if (!this.availableRadioTarget.checked && !this.unavailableRadioTarget.checked) return availabilityData
    while (currentDate <= endingDate) {
      const currentAvailability = availabilities.find(avail => isEqual(new Date(avail.startDate), currentDate))
      const availability = {
        id: currentAvailability?.id,
        hotelId: this.hotelIdValue,
        description: form.get('rate_and_availability[description]'),
        startDate: set(currentDate, { hours: 0, minutes: 0, seconds: 0 }),
        endDate: set(currentDate, { hours: 23, minutes: 59, seconds: 59 }),
        housingId: this.idValue,
        availability: this.availableRadioTarget.checked,
        reason: form.get('rate_and_availability[reason]')
      }

      availabilityData.push(availability)

      currentDate = addDays(currentDate, 1)
    }
    return availabilityData
  }
}
