import { Controller } from 'stimulus'
import airbnbData from './airbnb_data/airbnbTemplate'

export default class extends Controller {
  static targets = ['source']
  static values = {
    hotelId: String
  }

  async copyTemplate() {
    const text = this.sourceTarget.innerHTML
    const { templateName } = this.element.dataset
    const data = airbnbData(this.hotelIdValue).fr
    const dataEncoded = btoa(encodeURIComponent(JSON.stringify(data[templateName])))
    this.copyFormatted(text, dataEncoded)
  }

  copyFormatted(text, dataEncoded) {
    const element = document.createElement('div')
    Object.assign(element.style, {
      position: 'fixed',
      pointerEvents: 'none',
      opacity: 0,
      fontWeight: 300
    })
    element.innerHTML = text
    element.children[0].setAttribute('data-slate-fragment', dataEncoded)
    document.body.appendChild(element)
    window.getSelection().removeAllRanges()
    const range = document.createRange()
    range.selectNode(element)
    window.getSelection().addRange(range)
    document.execCommand('copy')
    document.body.removeChild(element)
  }
}
