import { addDays, format } from 'date-fns'

export class ManageAvailabilitiesCalendarBo {
  constructor(unAvailabilitiesStartDates) {
    this.dateCells = unAvailabilitiesStartDates
    this.cellUnavaibleParents = []
    this.patterns = []
  }

  getParentsUnavaiblesCells() {
    this.dateCells.forEach(date => {
      let currentDate = document.querySelector(`[data-date="${date}"].is-unavailable`)
      if (currentDate.classList.contains('is-unavailable')) {
        this.cellUnavaibleParents.push(currentDate.closest('.fc-timeline-bg-harness'))
      }
    })

    this.showDatesOnParents()
  }

  showDatesOnParents() {
    this.cellUnavaibleParents.forEach((cell, index) => {
      cell.setAttribute('data-date', this.dateCells[index])
    })
    this.findCellSelected()
  }

  findCellSelected() {
    const selectedCells = []
    const startDate = new Date(localStorage.getItem('startDate')).getTime()
    const endDate = new Date(localStorage.getItem('endDate')).getTime()
    this.cellUnavaibleParents.forEach(cell => {
      if (startDate <= new Date(cell.dataset.date).getTime() && new Date(cell.dataset.date).getTime() < endDate) {
        cell.classList.add('is-selected')
        selectedCells.push(cell)
      } else {
        cell.classList.remove('is-selected')
      }
    })
  }
}
