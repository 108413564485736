import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { step: String }
  static targets = ['accordionDropdown', 'dynamicVehicleName']

  connect() {}

  openingVehicle(e) {
    e.currentTarget.parentNode.parentNode.classList.toggle('accordion-dropdown-wrapper')
    this.accordionDropdownTarget.classList.toggle('accordion-dropdown-arrow--opened')
  }

  /**
   * Update dynamicely the vehicle name
   * @param {HTMLElement} event
   */
  updateDynamicVehicleName(event) {
    const value = event.currentTarget.value
    const vehicleNameInput = event.currentTarget.closest('div.accordion-dropdown-container').getElementsByTagName('p')[0]
    vehicleNameInput.innerText = value.length > 0 ? value : vehicleNameInput.dataset.vehicleDefaultName
  }
}