import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['errorMsgNumber']

  connect() {}

  verifyInputNumber(event) {
    const currentForm = event.currentTarget.closest('.ga-form')
    const inputNumberToVerify = currentForm.querySelectorAll('.input.integer input')
    let valid = true
    const inputCheckNumberRegex = /^\d+$/
    inputNumberToVerify.forEach(input => {
      if ((inputCheckNumberRegex.test(input.value) === false && Number(input.value) < 0) || input.value === '') {
        input.closest('.form__field-content').querySelector('[data-form-verify-target="errorMsgNumber"]').classList.remove('d-none')
        valid = false
      } else {
        input.closest('.form__field-content').querySelector('[data-form-verify-target="errorMsgNumber"]').classList.add('d-none')
      }
    })
  }
}
