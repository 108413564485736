import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['date', 'selectFirstOptionDisabled', 'warningArrival', 'toggleContainer']
  static values = { dateDisabled: Array }

  connect() {
    if (this.hasDateTarget) {
      this.dateTarget._flatpickr.set('disable', this.dateDisabledValue)
    }

    if (this.hasSelectFirstOptionDisabledTarget) {
      this.selectFirstOptionDisabledTargets.forEach(select => {
        select.querySelector('option:first-child').setAttribute('disabled', true)
      })
    }

    const nestedField = document.querySelector('#nestedField')

    nestedField !== null && nestedField.setAttribute('data-form-check-target', 'required')
  }

  kindSelectChanged(e) {
    const kind = e.currentTarget.value
    if (kind === 'other') {
      this.toggle(false)
    } else {
      this.toggle(true)
    }
  }

  toggle(visible) {
    if (visible) {
      this.toggleContainerTargets.forEach(target => target.classList.remove('d-none'))
    } else {
      this.toggleContainerTargets.forEach(target => target.classList.add('d-none'))
    }
  }

  twoDaysBeforeArrival() {
    this.warningArrivalTarget.classList.add('hidden')
    let daysBetweenDates = Math.round((this.dateTarget._flatpickr.selectedDates[0].getTime() - new Date().getTime()) / (1000 * 3600 * 24)) + 1
    daysBetweenDates <= 2 && this.warningArrivalTarget.classList.remove('hidden')
  }
}
