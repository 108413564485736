import { Controller } from 'stimulus'
import { useMainStore } from 'itineraries-editor/store'
import { setActivePinia } from 'pinia'
import pinia from 'packs/itineraries_editor'

export default class extends Controller {

  static targets = [
    'form',
    'fontPreview'
  ]

  toggleShowLogo(event) {
    let checkbox = event.target.closest('.js-toggle-container').getElementsByTagName('input')[0];
    event.target.classList.toggle('switch-toggle--off');
    event.target.classList.toggle('switch-toggle--on');

    checkbox.value = !(checkbox.value == 'true')
  }

  selectLanguage(event) {
    let inputLanguage = event.target
    let inputName = inputLanguage.dataset.inputName
    let inputFr = document.querySelector(`#${inputName}_fr`)
    let inputEn = document.querySelector(`#${inputName}_en`)
    inputFr.classList.toggle('d-none')
    inputEn.classList.toggle('d-none')
  }

  submitForm(event) {
    setActivePinia(pinia)
    const store = useMainStore()
    let itinerary = null
    store.saving = true
    fetch(this.formTarget.action, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: new FormData(this.formTarget)
    }).finally(() => {
      itinerary = store.fetchItems()
      store.saving = false
      store.printing = true
      store.show_logo = itinerary.show_logo
      store.logo = itinerary.logo
      store.font = itinerary.font
      document.querySelector(".js-form-modal__container").style.display = 'none'
    })
  }

  updateFontPreview(event) {
    let font = event.target.value
    this.fontPreviewTarget.style.fontFamily = font
  }
}