export function fadeIn(el, display = 'block', callback = () => {}) {
  el.style.opacity = 1
  el.style.display =
    display ||
    'block'(function fade() {
      let val = parseFloat(el.style.opacity)
      if (!((val += 0.3) > 1)) {
        el.style.opacity = val
        requestAnimationFrame(fade)
      }
    })()
  callback()
}

export function fadeOut(el, callback = () => {}) {
  el.style.opacity = 1(function fade() {
    if ((el.style.opacity -= 0.3) < 0) {
      el.style.display = 'none'
    } else {
      requestAnimationFrame(fade)
    }
  })()
  callback()
}

/* SLIDE UP */
export let slideUp = (target, duration = 500) => {
  target.style.transitionProperty = 'height, margin, padding'
  target.style.transitionDuration = duration + 'ms'
  target.style.boxSizing = 'border-box'
  target.style.height = target.offsetHeight + 'px'
  target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout(() => {
    target.style.display = 'none'
    target.style.removeProperty('height')
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
    //alert("!")
  }, duration)
}

/* SLIDE DOWN */
export let slideDown = (target, duration = 500) => {
  target.style.removeProperty('display')
  let display = window.getComputedStyle(target).display
  if (display === 'none') display = 'block'
  target.style.display = display
  let height = target.offsetHeight
  target.style.overflow = 'hidden'
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = 'border-box'
  target.style.transitionProperty = 'height, margin, padding'
  target.style.transitionDuration = duration + 'ms'
  target.style.height = height + 'px'
  target.style.removeProperty('padding-top')
  target.style.removeProperty('padding-bottom')
  target.style.removeProperty('margin-top')
  target.style.removeProperty('margin-bottom')
  window.setTimeout(() => {
    target.style.removeProperty('height')
    target.style.removeProperty('overflow')
    target.style.removeProperty('transition-duration')
    target.style.removeProperty('transition-property')
  }, duration)
}

// /* TOOGLE */
export const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

export function show(element) {
  element.style.display = 'block'
}

export function hide(element) {
  element.style.display = 'none'
}

export function addDnoneClass(element) {
  element.classList.add('d-none')
}

export function removeDnoneClass(element) {
  element.classList.remove('d-none')
}
