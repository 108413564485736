import { Controller } from 'stimulus'
import translation from '../utils/translation'

export default class extends Controller {
  static values = { step: String }
  static targets = ['form', 'radioVehicle', 'nextBtn', 'nextBtnSecond', 'bagCount', 'partySize']

  connect() {
    const nextBtn = document.querySelector('#nextBtn')
    nextBtn.addEventListener('click', this.nextStep)
    if (parseInt(this.stepValue) === 3) {
      nextBtn.innerText = translation[I18n].multiform.send
    } else {
      nextBtn.innerText = translation[I18n].multiform.next_step
    }
    const vehicleSelected = document.querySelector('.radio-card-active')
    if (vehicleSelected) {
      this.setLimitSize(vehicleSelected)
    }
  }

  disconnect() {
    document.removeEventListener('click', this.nextStep)
  }

  nextStep() {
    document.querySelector('#nextBtnSecond').click()
  }

  vehicleHasChoice(event) {
    this.radioVehicleTargets.forEach(element => {
      element.classList.remove('radio-card-active')
    })
    event.currentTarget.classList.add('radio-card-active')
    const { maxpartysize, maxbagcount } = event.currentTarget.dataset
    this.partySizeTarget.value = ''
    this.bagCountTarget.value = ''
    this.setLimitSize(event.currentTarget)
  }

  setLimitSize(vehicleElement) {
    const { maxpartysize, maxbagcount } = vehicleElement.dataset
    this.partySizeTarget.setAttribute('max', maxpartysize)
    this.bagCountTarget.setAttribute('max', maxbagcount)
  }
}
