import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parentInput', 'input', 'savedInput']
  static values = {
    name: String
  }

  connect() {
    this.parentInputTargets.forEach(input => {
      if (input.classList.contains('active')) {
        input.querySelector('input').checked = true
      }
    })

    const allFormsCloned = document.querySelectorAll('.cloned')
    const currentForm = allFormsCloned[allFormsCloned.length - 1]
    if (currentForm !== undefined) {
      currentForm.querySelector('.form__row-radio-supinput input').classList.remove('required')
    }
  }

  setDefaultValue() {
    this.savedInputTarget.value = this.nameValue
  }

  manageActiveClass(event) {
    const currentTarget = event.currentTarget

    let inputSup
    this.parentInputTargets.forEach(input => {
      input.classList.remove('active')
      inputSup = input.querySelector('.form__row-radio-supinput')
      if (inputSup !== null) {
        inputSup.querySelector('span').classList.add('d-none')
      }
    })
    currentTarget.classList.add('active')

    this.inputTargets.forEach(input => {
      if (input.closest('.form__row-radio').classList.contains('active')) {
        input.checked = true
      } else {
        input.checked = false
      }
    })

    if (event.currentTarget.querySelector('.form__row-radio-supinput') !== null) {
      // Ce nouveau input doit être required
      let inputSupInput = event.currentTarget.querySelector('.form__row-radio-supinput input')
      inputSupInput.value = ''
      inputSupInput.classList.add('required')
      inputSupInput.classList.remove('optional')

      // Je récupére le label pour y ajouter l'étoile avant le label
      let inputSupLabel = event.currentTarget.querySelector('.form__row-radio-supinput label')
      inputSupLabel.querySelector('abbr') !== null && inputSupLabel.querySelector('abbr').remove()
      // Je dois créer l'étoile indice visuelle d'un champ requis
      let starRequired = document.createElement('abbr')
      starRequired.setAttribute('title', 'required')
      starRequired.innerText = '*'
      inputSupLabel.prepend(starRequired)
    } else {
      inputSup.querySelector('input').classList.remove('required')
    }
  }
}
