import { janaHttpFetch } from './api'

class VendorCategory {
  static async find(id) {
    return janaHttpFetch(`vendor_categories/${id}`)
  }

  static async form(id, bookingId) {
    return janaHttpFetch(`vendor_categories/form/${id}?booking_id=${bookingId}`)
  }
}

export { VendorCategory }
