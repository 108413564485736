import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toast', 'toastNoShow']

  connect() {
    this.hasToastTarget && this.hideToastMessage()
  }

  hideToastMessage() {
    setTimeout(() => {
      this.toastTarget.classList.add('hidden')
    }, 3500)
  }

  showToastMessage() {
    this.toastNoShowTarget.classList.add('tw-block')
    this.toastNoShowTarget.classList.remove('tw-hidden')
    setTimeout(() => {
      this.toastNoShowTarget.classList.remove('tw-block')
      this.toastNoShowTarget.classList.add('tw-hidden')
    }, 3500)
  }
}
