import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'btnFooterInSidePanel',
    'ctaTextAreaArrivalProcedure',
    'ctaTextAreaDepartureProcedure',
    'overlayWithLoader',
    'sidePanel',
    'textAreaArrivalProcedure',
    'textAreaDepartureProcedure'
  ]

  overlay = document.querySelector('.overlay')

  connect() {}

  closeSidepanel() {
    this.sidePanelTarget.classList.remove('is-open')
    this.sidePanelTarget.classList.add('is-close')
    this.overlay.classList.add('tw-hidden')
    this.overlay.classList.add('hidden')
  }

  closeSidepanelWithOverlay() {
    this.closeSidepanel()
    this.overlayWithLoaderTarget.classList.remove('hidden')
    setTimeout(() => {
      this.overlayWithLoaderTarget.classList.add('hidden')
    }, 5000)
  }

  openSidepanel() {
    this.sidePanelTarget.classList.contains('is-close') && this.sidePanelTarget.classList.remove('is-close')
    this.sidePanelTarget.classList.add('is-open')
    this.overlay.classList.remove('tw-hidden')
    this.overlay.classList.contains('hidden') && this.overlay.classList.remove('hidden')
  }

  sidePanelWithBtnFooter() {
    this.btnFooterInSidePanelTarget.querySelector('.navbar-bottom').classList.toggle('hidden')
  }
}
