import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['documentsInput', 'newDocumentsDropdown', 'newDocumentsStore', 'submitBtn']

  displayNewFiles(event) {
    const filesInput = event.currentTarget
    const files = filesInput.files
    let lastKey = 0
    let offset = 0

    if (this.hasNewDocumentsStoreTarget) {
      const oldFiles = this.newDocumentsStoreTarget.files
      if (oldFiles.length > 1) {
        lastKey = oldFiles.length - 1
        offset = 1
      }
    }

    if (this.hasNewDocumentsDropdownTarget && this.hasSubmitBtnTarget) {
      let option = null
      if (files.length) {
        this.storeNewFiles(files)
        this.submitBtnTarget.classList.remove('d-none')
        Object.keys(files).forEach(key => {
          option = document.querySelector('#tr-edit-files').cloneNode(true)
          option.id = ''
          option.classList.remove('d-none')
          option.childNodes[1].appendChild(document.createTextNode(files[key].name))
          option.childNodes[3].dataset.key = lastKey + parseInt(key) + offset
          option.childNodes[5].childNodes[1].name = 'expense[export_pdf][]'
          option.childNodes[5].childNodes[3].name = 'expense[export_pdf][]'
          option.childNodes[5].childNodes[3].dataset.export = 'export_pdf'
          option.childNodes[7].childNodes[1].name = 'expense[export_xlsx][]'
          option.childNodes[7].childNodes[3].name = 'expense[export_xlsx][]'
          option.childNodes[7].childNodes[3].dataset.export = 'export_xlsx'
          this.newDocumentsDropdownTarget.appendChild(option)
        })
      }
    }
  }

  storeNewFiles(files) {
    const dt = new DataTransfer()
    if (this.hasDocumentsInputTarget && this.hasNewDocumentsStoreTarget) {
      const oldFiles = this.newDocumentsStoreTarget.files
      if (oldFiles.length) {
        for (let i = 0; i < oldFiles.length; i++) {
          dt.items.add(oldFiles[i])
        }
      }
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          dt.items.add(files[i])
        }
      }
      this.newDocumentsStoreTarget.files = dt.files
    }
  }

  removeNewFile(event) {
    event.stopPropagation()
    const file = event.currentTarget
    const key = parseInt(file.dataset.key)
    file.closest('tr').remove()
    this.removeNewFileFromFileList(key) // FileList is readonly and cannot be modified
  }

  removeNewFileFromFileList(key) {
    if (this.hasNewDocumentsStoreTarget) {
      const dt = new DataTransfer()
      const files = this.newDocumentsStoreTarget.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (key !== i) {
          dt.items.add(file)
        } else {
          dt.items.add(new File(['null'], 'null.txt')) // add a useless file to keep the key for others files
        }
      }
      this.newDocumentsStoreTarget.files = dt.files
    }
  }

  removeHiddenCheckbox(event) {
    const checkbox = event.currentTarget
    const exportType = checkbox.dataset.export
    const parent = checkbox.closest('td')
    let emptyInput = parent.querySelector('.hidden-checkbox-input')
    if (emptyInput && checkbox.checked) {
      emptyInput.remove()
    } else if (parent && exportType) {
      emptyInput = `<input name='expense[${exportType}][]' type='hidden' value='off' class='hidden-checkbox-input'>`
      parent.insertAdjacentHTML('afterbegin', emptyInput)
    }
  }
}
