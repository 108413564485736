export default {
	fr: {
		multiform: {
			send: 'Envoyer',
			next_step: 'Étape suivante'
		}
	},
	en: {
		multiform: {
      send: 'Send',
			next_step: 'Next Step'
		}
	}
}