import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    url: String,
    placeholder: String,
    selectedguest: {
      type: Object,
      default: {}
    }
  }

  select = null

  connect() {
    const formElement = document.querySelector('#new_edit_booking_form')
    const formController = this.application.getControllerForElementAndIdentifier(formElement, 'bookings--form')
    let url = window.location.origin + this.urlValue
    let selectize = $('#select-search-input').selectize({
      delimiter: ' - ',
      placeholder: this.placeholderValue,
      maxItems: 1,
      valueField: 'id',
      labelField: 'full_name',
      searchField: ['first_name', 'last_name'],
      options: [],
      onInitialize: function () {
        $('#select-search-component').attr('data-selectize-load', 'true')
        $('#select-search-component')[0].dispatchEvent(selectizeInitializedEvent)
      }
    })
    if (this.hasSelectedguestValue && Object.keys(this.selectedguestValue).length) {
      $('#select-input-value')[0].value = this.selectedguestValue.id
      $('#select-search-input')
        .selectize()[0]
        .selectize.addOption({
          full_name: this.selectedguestValue.first_name + ' ' + this.selectedguestValue.last_name,
          id: this.selectedguestValue.id
        })
      $('#select-search-input').selectize()[0].selectize.addItem(this.selectedguestValue.id)
    }
    this.select = selectize[0].selectize
    selectize[0].selectize.on('type', function (e) {
      // CHECK INPUT VALUE IS MORE THAN 3 CHAR
      if (e.length >= 3) {
        $.ajax({
          type: 'GET',
          data: `search=${e}`,
          url: url,
          success: function (data) {
            // order('last_name ASC')
            let orderedData = data.sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
            // REPLACE COLLECTION RESPONSE IN SELECTIZE COLLECTION
            selectize[0].selectize.clear(true)
            selectize[0].selectize.clearOptions()
            selectize[0].selectize.addOption(orderedData)
            selectize[0].selectize.refreshOptions(true)
          }
        })
      }
    })

    // Send Guest Value to input value to get it through params in controller
    selectize[0].selectize.on('change', guestId => {
      $('#select-input-value')[0].value = guestId
      formController.guestChange(guestId)
    })
  }
}
