import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'entityId',
    'vendorableSelect',
    'vendorableId',
    'vendorableType',
    'bookingSelect',
    'bookingId',
    'documentsDropdown',
    'documentsCount',
    'documentsInput',
    'newDocumentsDropdown',
    'bookingDocumentsIds',
    'newDocumentsStore',
    'amountInput',
    'dateInput'
  ]

  static values = {
    catTitle: String,
    vendorTitle: String,
    vendorableTitle: String,
    bookingTitle: String,
    expenseBookingId: Number,
    viewName: String,
    emptyDocument: String
  }

  connect() {
    this.setBooking(false)
  }

  setVendorable(changedByUser = true) {
    const entityID = this.entityIdTarget.value
    const entityModel = this.entityIdTarget.dataset.model
    const editFrom = document.querySelector('#new-edit-expense')
    let vendorableID = null
    let model = null
    if (editFrom) {
      vendorableID = $('#new-edit-expense').find('#vendorableSelect option:selected').val()
      model = $('#new-edit-expense').find('#vendorableSelect option:selected').data('model')
    } else {
      vendorableID = $('#vendorableSelect option:selected').val()
      model = $('#vendorableSelect option:selected').data('model')
    }

    if (this.hasVendorableIdTarget && vendorableID) {
      this.vendorableIdTarget.value = vendorableID
    } else if (this.hasVendorableIdTarget) {
      this.vendorableIdTarget.value = null
    }

    if (this.hasVendorableTypeTarget && model) {
      this.vendorableTypeTarget.value = model
    } else if (this.hasVendorableTypeTarget) {
      this.vendorableTypeTarget.value = null
    }

    if (vendorableID && changedByUser && model && entityID && entityModel && this.hasViewNameValue) {
      this.filterBookings(vendorableID, model, entityID, entityModel, this.viewNameValue)
    } else if (!vendorableID && changedByUser && entityID && entityModel && this.hasViewNameValue) {
      this.resetBookings(entityID, entityModel, this.viewNameValue)
    }
  }

  setBooking(changedByUser = true) {
    const entityID = this.entityIdTarget.value
    const entityModel = this.entityIdTarget.dataset.model
    const editFrom = document.querySelector('#new-edit-expense')
    let bookingID = null
    if (editFrom) {
      bookingID = $('#new-edit-expense').find('#bookingSelect option:selected').val()
    } else {
      bookingID = $('#bookingSelect option:selected').val()
    }

    if (this.hasBookingIdTarget && bookingID) {
      this.bookingIdTarget.value = bookingID
    } else if (this.hasBookingIdTarget) {
      this.bookingIdTarget.value = null
    }

    if (changedByUser && bookingID) {
      this.selectVendorable(bookingID)
    } else if (changedByUser && !bookingID && entityID && entityModel && this.hasViewNameValue) {
      this.resetBookings(entityID, entityModel, this.viewNameValue)
      this.modifyVendorablesOptionSelected(null, true)
    }

    if (bookingID) {
      this.fetchBookingDocuments(bookingID)
      this.autocompleteAmountDate(bookingID)
    } else if (this.hasDocumentsDropdownTarget && this.hasEmptyDocumentValue && this.hasBookingDocumentsIdsTarget) {
      this.documentsDropdownTarget.innerHTML = this.emptyDocumentValue
      this.bookingDocumentsIdsTarget.value = null
      this.documentsDropdownTarget.closest('table').querySelector('thead').classList.add('d-none')
      this.changeDocumentsCount()
      this.amountInputTarget.value = null
    }
  }

  selectVendorable(bookingID) {
    const urlParams = new URLSearchParams({
      booking_id: bookingID,
      filter_on: 'vendorables'
    })
    this.modifyVendorablesOptionSelected(urlParams, false)
  }

  autocompleteAmountDate(bookingID) {
    const urlParams = new URLSearchParams({
      booking_id: bookingID
    })
    this.fetchBookingData(urlParams)
  }

  filterBookings(vendorableID, vendorableModel, entityID, entityModel, viewName) {
    const urlParams = new URLSearchParams({
      vendorable_id: vendorableID,
      vendorable_class: vendorableModel,
      entity_id: entityID,
      entity_class: entityModel,
      filter_on: 'bookings',
      view_name: viewName
    })
    this.modifyBookingsSelect(urlParams)
  }

  resetBookings(entityID, entityModel, viewName) {
    const urlParams = new URLSearchParams({
      entity_id: entityID,
      entity_class: entityModel,
      filter_on: 'bookings',
      view_name: viewName
    })
    this.modifyBookingsSelect(urlParams)
  }

  modifyVendorablesOptionSelected(urlParams, reset) {
    if (urlParams && !reset) {
      const url = `/api/v1/find_vendorables_or_bookings_for_expenses?${urlParams.toString()}`
      this.fetchUrl(url).then(res => {
        if (this.hasVendorableSelectTarget) {
          const vendorCategories = res.vendor_categories
          const vendors = res.vendors

          if (vendorCategories.length) {
            const category = vendorCategories[0]
            document.querySelector(`#category-option-${category.id}`).selected = true
          }

          if (vendors.length) {
            const vendor = vendors[0]
            document.querySelector(`#vendor-option-${vendor.id}`).selected = true
          }
          this.setVendorable(false)
        }
      })
    } else if (reset) {
      document.querySelectorAll('.vendorable-option').forEach(option => {
        option.selected = false
      })
      this.setVendorable(false)
    }
  }

  fetchBookingData(urlParams) {
    const url = `/api/v1/find_booking_data?${urlParams.toString()}`
    this.fetchUrl(url).then(res => {
      if (this.hasAmountInputTarget && this.hasDateInputTarget) {
        const bookingPrice = res.price
        const bookingDate = res.date
        this.amountInputTarget.value = parseFloat(bookingPrice)
        this.dateInputTarget.value = bookingDate
        loadFlatpickr()
      }
    })
  }

  modifyBookingsSelect(urlParams) {
    const url = `/api/v1/find_vendorables_or_bookings_for_expenses?${urlParams.toString()}`
    this.fetchUrl(url).then(res => {
      if (this.hasBookingSelectTarget && this.hasBookingTitleValue) {
        this.bookingSelectTarget.textContent = ''
        const bookings = res.bookings
        let options = `<option value='' selected>${this.bookingTitleValue}</option>`

        if (bookings.length) {
          bookings.forEach(booking => {
            const disabledNewCondition = booking.disabled === 'true' && booking.view_name === 'new'
            const disabledEditCondition =
              booking.disabled === 'true' &&
              booking.view_name === 'edit' &&
              this.hasExpenseBookingIdValue &&
              this.expenseBookingIdValue !== booking.id
            let option = null
            if (disabledNewCondition || disabledEditCondition) {
              option = `<option disabled>${booking.name}</option>`
            } else {
              option = `<option value=${booking.id}>${booking.name}</option>`
            }
            options += option
          })
        }

        this.bookingSelectTarget.innerHTML = options
        this.setBooking(false)
      }
    })
  }

  fetchBookingDocuments(bookingId) {
    const urlParams = new URLSearchParams({
      booking_id: bookingId
    })
    this.modifyDocumentsDropdown(urlParams)
  }

  modifyDocumentsDropdown(urlParams) {
    const url = `/api/v1/find_documents_from_booking?${urlParams.toString()}`
    this.fetchUrl(url).then(res => {
      if (this.hasDocumentsDropdownTarget && this.hasBookingDocumentsIdsTarget) {
        this.documentsDropdownTarget.textContent = ''
        const documents = res.documents
        let option = null
        let ids = []

        if (documents.length) {
          documents.forEach(doc => {
            option = document.querySelector('#tr-to-clone').cloneNode(true)
            option.id = ''
            option.classList.remove('d-none')
            option.childNodes[1].appendChild(document.createTextNode(doc.filename))
            option.childNodes[3].childNodes[1].className = 'd-none'
            this.documentsDropdownTarget.appendChild(option)
            ids.push(doc.id)
          })
          this.documentsDropdownTarget.closest('table').querySelector('thead').classList.remove('d-none')
          this.bookingDocumentsIdsTarget.value = ids
        } else if (this.hasEmptyDocumentValue) {
          option = document.createTextNode(this.emptyDocumentValue)
          this.documentsDropdownTarget.closest('table').querySelector('thead').classList.add('d-none')
          this.bookingDocumentsIdsTarget.value = null
          this.documentsDropdownTarget.appendChild(option)
        }

        this.changeDocumentsCount()
      }
    })
  }

  removeHiddenCheckbox(event) {
    const checkbox = event.currentTarget
    const exportType = checkbox.dataset.export
    const parent = checkbox.closest('td')
    let emptyInput = parent.querySelector('.hidden-checkbox-input')
    if (emptyInput && checkbox.checked) {
      emptyInput.remove()
    } else if (parent && exportType) {
      emptyInput = `<input name='expense[${exportType}][]' type='hidden' value='off' class='hidden-checkbox-input'>`
      parent.insertAdjacentHTML('afterbegin', emptyInput)
    }
  }

  displayNewFiles(event) {
    const filesInput = event.currentTarget
    const files = filesInput.files
    let lastKey = 0
    let offset = 0

    if (this.hasNewDocumentsStoreTarget) {
      const oldFiles = this.newDocumentsStoreTarget.files
      if (oldFiles.length > 1) {
        lastKey = oldFiles.length - 1
        offset = 1
      }
    }

    if (this.hasNewDocumentsDropdownTarget && this.hasDocumentsDropdownTarget) {
      let option = null
      if (files.length) {
        this.storeNewFiles(files)
        Object.keys(files).forEach(key => {
          option = document.querySelector('#tr-to-clone').cloneNode(true)
          option.id = ''
          option.classList.remove('d-none')
          option.childNodes[1].appendChild(document.createTextNode(files[key].name))
          option.childNodes[3].dataset.key = lastKey + parseInt(key) + offset
          option.childNodes[5].childNodes[1].name = 'expense[export_pdf][]'
          option.childNodes[5].childNodes[3].name = 'expense[export_pdf][]'
          option.childNodes[5].childNodes[3].dataset.export = 'export_pdf'
          option.childNodes[7].childNodes[1].name = 'expense[export_xlsx][]'
          option.childNodes[7].childNodes[3].name = 'expense[export_xlsx][]'
          option.childNodes[7].childNodes[3].dataset.export = 'export_xlsx'
          this.newDocumentsDropdownTarget.appendChild(option)
        })
        this.documentsDropdownTarget.closest('table').querySelector('thead').classList.remove('d-none')
        this.changeDocumentsCount()
      }
    }
  }

  changeDocumentsCount() {
    if (this.hasDocumentsCountTarget) {
      const count = document.querySelectorAll('.expense-new-files-inputs').length - 1
      this.documentsCountTarget.innerHTML = count
    }
  }

  storeNewFiles(files) {
    const dt = new DataTransfer()
    if (this.hasDocumentsInputTarget && this.hasNewDocumentsStoreTarget) {
      const oldFiles = this.newDocumentsStoreTarget.files
      if (oldFiles.length) {
        for (let i = 0; i < oldFiles.length; i++) {
          dt.items.add(oldFiles[i])
        }
      }
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          dt.items.add(files[i])
        }
      }
      this.newDocumentsStoreTarget.files = dt.files
    }
  }

  removeNewFile(event) {
    event.stopPropagation()
    const file = event.currentTarget
    const key = parseInt(file.dataset.key)
    file.closest('tr').remove()
    this.changeDocumentsCount()
    this.removeNewFileFromFileList(key) // FileList is readonly and cannot be modified
  }

  removeNewFileFromFileList(key) {
    if (this.hasNewDocumentsStoreTarget) {
      const dt = new DataTransfer()
      const files = this.newDocumentsStoreTarget.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (key !== i) {
          dt.items.add(file)
        } else {
          dt.items.add(new File(['null'], 'null.txt')) // add a useless file to keep the key for others files
        }
      }
      this.newDocumentsStoreTarget.files = dt.files
    }
  }

  async fetchUrl(url) {
    let results = null
    await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(res => res.json().then(data => (results = data)))
    return results
  }
}
