import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['btnSubmit', 'checkboxChecked', 'checklistItem', 'itemErrorMessage', 'providerPhotos']
  static values = { url: String }
  allItemsCheckboxes = [...this.checklistItemTargets]

  connect() {
    if (this.hasChecklistItemTargets) {
      this.checklistItemTargets.forEach(item => {
        let checkbox = item.querySelector('span input[type="checkbox"]')
        item.classList.contains('is-checked') ? checkbox.setAttribute('checked', 'checked') : checkbox.removeAttribute('checked')
      })
    }
    if (this.allItemsCheckboxes.length === 0 && this.hasBtnSubmitTarget) {
      this.hasBtnSubmitTarget && this.btnSubmitTarget.removeAttribute('data-action')
    }

    this.checklistsItemAllChecked()
  }

  checklistItemChecked(event) {
    const label = event.target.closest('label')
    const form = event.target.closest('form')
    if (this.validatePhotoRequired(event, label, form) === false) return
    label.classList.toggle('is-checked')
    form.requestSubmit()
  }

  validatePhotoRequired(event, label, form) {
    const itemErrorMessage = form.querySelector('#photoErrorMessage')
    itemErrorMessage.classList.add('hidden')
    if (event.currentTarget.dataset.photoRequired === 'false' || label.classList.contains('is-checked')) return true
    if (event.currentTarget.dataset.photoCount == 0) {
      itemErrorMessage.classList.remove('hidden')
      return false
    }
  }

  checklistsItemAllChecked() {
    let allItemsCheckboxesChecked = []
    this.allItemsCheckboxes.forEach(checkbox => {
      checkbox.classList.contains('is-checked') && allItemsCheckboxesChecked.push(checkbox)
    })

    if (this.allItemsCheckboxes.length !== allItemsCheckboxesChecked.length) {
      this.btnSubmitTarget.setAttribute('data-action', 'components--ui--modal#openModalWithPreventDefault')
    } else {
      this.hasBtnSubmitTarget && this.btnSubmitTarget.removeAttribute('data-action')
    }
  }
}
