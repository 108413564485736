import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'header', 'toast']

  connect() {
    if (this.hasHeaderTarget) {
      this.headerTarget.addEventListener('mouseout', () => {
        this.hasDropdownTarget && this.dropdownTarget.classList.add('hidden')
      })
    }
  }

  openCloseDropdown(event) {
    event.preventDefault()
    this.dropdownTarget.classList.toggle('hidden')
  }
}
