import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    labelCompanyAirport: String,
    labelCompanyTrain: String,
    labelTravelNumberAirport: String,
    labelTravelNumberTrain: String
  }

  static targets = ['input', 'pickupTransport', 'transportType']

  #autocompleteInputs = []

  initialize() {
    this.placeChanged = this.placeChanged.bind(this)
  }

  connect() {
    setTimeout(() => {
      if (typeof google !== 'undefined' && this.hasInputTarget) this.#initAutocomplete()
    }, 500)
  }

  #initAutocomplete() {
    this.#autocompleteInputs = []
    this.inputTargets.forEach(input => {
      const autocomplete = new google.maps.places.Autocomplete(input)
      autocomplete.addListener('place_changed', this.placeChanged())
      this.#autocompleteInputs.push(autocomplete)
    })
  }

  placeChanged() {
    this.findPlaceTypes()
    if (this.autocomplete !== undefined) {
      this.place = this.autocomplete.getPlace()
    }
  }

  findPlaceTypes() {
    this.#autocompleteInputs.forEach((gmapInput, index) => {
      const place = gmapInput.getPlace()
      if (!place) return
      if (place.types.includes('airport')) {
        this.displayTransportationInfos('airport', index)
      } else if (/gare/i.test(this.inputTargets[index].value)) {
        this.displayTransportationInfos('train_station', index)
      } else {
        this.hideTransportationInfos(index)
      }
    })
  }

  hideTransportationInfos(index) {
    if (!this.hasPickupTransportTarget) return
    this.pickupTransportTargets[index].classList.add('d-none')
    this.transportTypeTargets[index].value = ''
    this.pickupTransportTargets[index].querySelectorAll('input').forEach(input => (input.value = ''))
  }

  displayTransportationInfos(type, index) {
    if (!this.hasPickupTransportTarget) return
    this.pickupTransportTargets[index].classList.remove('d-none')
    this.transportTypeTargets[index].value = type
    const labels = this.transportTypeTargets[index].parentElement.getElementsByTagName('label')
    this.displayRightLabelFromVehicleType(labels, type)
  }

  displayRightLabelFromVehicleType(labels, transportType) {
    if (transportType === 'airport') {
      labels[0].innerHTML = this.labelCompanyAirportValue
      labels[1].innerHTML = this.labelTravelNumberAirportValue
    } else if (transportType === 'train_station') {
      labels[0].innerHTML = this.labelCompanyTrainValue
      labels[1].innerHTML = this.labelTravelNumberTrainValue
    }
  }

  preventSubmit(e) {
    if (e.key === 'Enter') e.preventDefault() // prevent form submit on Enter.
  }
}
