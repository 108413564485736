import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    this.initRadio('owner')
    this.initRadio('guest')
    this.initRadio('guest_without_stay')
    this.initRadio('owner_under_contract')

    this.manageRadio('owner')
    this.manageRadio('guest')
    this.manageRadio('guest_without_stay')
    this.manageRadio('owner_under_contract')
  }

  initRadio(type) {
    let cssClass = `.js-radio-${type}`

    document.querySelectorAll(cssClass).forEach((e) => e.classList.remove('custom-radio--checked'))
    document.querySelectorAll(`${cssClass} input[type=radio]`).forEach((r) => { if (r.checked) r.closest('.custom-radio').classList.add('custom-radio--checked')})
  }

  manageRadio(type) {
    let cssClass = `.js-radio-${type}`

    document.querySelectorAll(cssClass).forEach((element) => {
      element.addEventListener('click', (event) =>{
        document.querySelectorAll(cssClass).forEach((e) => e.classList.remove('custom-radio--checked'))
        event.target.closest('.custom-radio').classList.add('custom-radio--checked')
        event.target.parentElement.querySelector('input[type=radio]').checked = true
      })
    })
  }
}


