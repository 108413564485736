const airbnbData = hotelId => ({
  fr: {
    firstMessage: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Bonjour '
          },
          {
            type: 'variable',
            defaultVariable: 'prénom du voyageur',
            variableId: 'GUEST_FIRST_NAME',
            children: [
              {
                text: ''
              }
            ]
          },
          {
            text: ','
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nous vous remercions chaleureusement pour votre réservation et sommes ravis que vous nous ayez choisis pour votre prochain séjour !'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Afin de préparer au mieux votre arrivée et de vous transmettre toutes les informations importantes, nous vous serions reconnaissants de bien vouloir nous communiquer dès maintenant vos coordonnées en remplissant ce court formulaire : '
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: `${window.location.origin}/guest_app/stay_registrations/airbnb/${hotelId}/`
          },
          {
            type: 'variable',
            defaultVariable: 'code de confirmation',
            variableId: 'CONFIRMATION_CODE',
            children: [
              {
                text: ''
              }
            ]
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Après avoir complété le formulaire, vous recevrez un message de confirmation et accéderez à votre espace personnel, spécialement créé pour votre séjour.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nous restons à votre entière disposition pour répondre à toutes vos questions.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: "Dans l'attente de vous accueillir,"
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'À très bientôt,'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'La Conciergerie'
          }
        ]
      }
    ],
    secondMessage: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Bonjour '
          },
          {
            type: 'variable',
            defaultVariable: 'prénom du voyageur',
            variableId: 'GUEST_FIRST_NAME',
            children: [
              {
                text: ''
              }
            ]
          },
          {
            text: ','
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nous espérons que vous allez bien.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: `Votre séjour approche et nous sommes impatients de vous accueillir. Si vous n'avez pas encore rempli le formulaire pour nous communiquer vos coordonnées, veuillez le faire dès à présent en cliquant ici : ${window.location.origin}/guest_app/stay_registrations/airbnb/${hotelId}/`
          },
          {
            type: 'variable',
            defaultVariable: 'code de confirmation',
            variableId: 'CONFIRMATION_CODE',
            children: [
              {
                text: ''
              }
            ]
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Si vous l’avez déjà complété, merci beaucoup – vous pouvez ignorer ce rappel.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Une fois le formulaire complété, vous recevrez un message de confirmation et pourrez accéder à votre espace personnel, conçu spécialement pour votre séjour.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nous sommes à votre entière disposition pour toute question que vous pourriez avoir.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: "Dans l'attente de vous accueillir très bientôt,"
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'La Conciergerie'
          }
        ]
      }
    ],
    thirdMessage: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Bonjour '
          },
          {
            type: 'variable',
            defaultVariable: 'prénom du voyageur',
            variableId: 'GUEST_FIRST_NAME',
            children: [
              {
                text: ''
              }
            ]
          },
          {
            text: ','
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nous espérons que vous allez bien.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: `Votre séjour est imminent ! Si vous n’avez pas encore fourni vos coordonnées, il est crucial de remplir ce formulaire dès que possible pour éviter de perdre l'accès à votre espace personnel : ${window.location.origin}/guest_app/stay_registrations/airbnb/${hotelId}/`
          },
          {
            type: 'variable',
            defaultVariable: 'code de confirmation',
            variableId: 'CONFIRMATION_CODE',
            children: [
              {
                text: ''
              }
            ]
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: "Si vous avez déjà rempli le formulaire, vous n'avez rien d'autre à faire et nous vous remercions pour votre réactivité. 🙂"
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Nous sommes à votre entière disposition pour répondre à toute question.'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'À très bientôt,'
          }
        ]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'La Conciergerie'
          }
        ]
      }
    ]
  }
})

export default airbnbData
