import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['stayProject', 'customFilter', 'filterGuestsOn']
  static values = {
    filter: String,
    guestId: Number
  }

  connect() {
    if (this.hasGuestIdValue && this.guestIdValue !== 0) {
      enabledStayProjectFilterOption()
    } else {
      disabledStayProjectFilterOption()
    }

    if (this.hasFilterValue) {
      if (this.filterValue === 'stayProject') {
        $('#stay_project').prop('checked', true)
      } else if (this.filterValue === 'customFilter') {
        $('#custom_filter').prop('checked', true)
      }
      this.toggleDisplayNoneClass(this.filterValue)
    }
  }

  displayInputFilter(event) {
    const inputName = event.currentTarget.dataset.input
    this.toggleDisplayNoneClass(inputName)

    if (this.hasFilterGuestsOnTarget) {
      this.filterGuestsOnTarget.value = inputName
    }
  }

  toggleDisplayNoneClass(filter) {
    if (filter === 'stayProject' && this.hasStayProjectTarget && this.hasCustomFilterTarget) {
      this.stayProjectTarget.classList.remove('d-none')
      this.customFilterTarget.classList.add('d-none')
    } else if (filter === 'customFilter' && this.hasStayProjectTarget && this.hasCustomFilterTarget) {
      this.customFilterTarget.classList.remove('d-none')
      this.stayProjectTarget.classList.add('d-none')
    }
  }
}
