import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tooltipsMsg', 'tooltipsIcon']

  connect() {
    if (document.querySelector('.form__field-with-tooltip label') !== undefined) {
      const labelsList = [...document.querySelectorAll('.form__field-with-tooltip label')]
      labelsList.forEach((label, index) => {
        let sizeOfElement = label.offsetWidth
        console.log(sizeOfElement)
        this.tooltipsIconTargets[index].style.left = sizeOfElement + 6 + 'px'
      })
    }
  }

  showTooltips() {
    this.tooltipsMsgTarget.classList.add('block')
  }

  hideTooltips() {
    this.tooltipsMsgTarget.classList.remove('block')
  }
}
