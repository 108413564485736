import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { start: String, end: String }
  static targets = ['recurrencyFields', 'recurrencyForm', 'inputDate', 'inputTime', 'inputEndingDate', 'inputEndingTime']
  counter = 2

  handleClick() {
    // Create a clone of element
    const clone = this.recurrencyFormTarget.cloneNode(true)
    // Change the id attribute of the newly created element
    clone.setAttribute('id', `recurrency-form-${this.counter}`)
    // Change the id inputs of the newly created element for inputs
    const inputs = clone.getElementsByTagName('input')
    const dateInput = inputs[0]
    const timeInput = inputs[2]
    const endingDateInput = inputs[3]
    const endingTimeInput = inputs[5]

    this.setAttributes(dateInput, { id: `booking_date-${this.counter}`, name: `recurrent_dates[${this.counter}][date]`, value: '' })
    this.setAttributes(timeInput, { id: `booking_time-${this.counter}`, name: `recurrent_dates[${this.counter}][time]` })
    this.setAttributes(endingDateInput, {
      id: `booking_ending_date-${this.counter}`,
      name: `recurrent_dates[${this.counter}][ending_date]`,
      value: ''
    })
    this.setAttributes(endingTimeInput, { id: `booking_ending_time-${this.counter}`, name: `recurrent_dates[${this.counter}][ending_time]` })

    // remove disabled class to be able to delete the field
    if ($('.recurrence-forms')[0].childElementCount > 0) {
      $('.recurrence')[0].getElementsByTagName('span')[1].classList.remove('disabled')
      clone.getElementsByTagName('span')[1].classList.remove('disabled')
    }

    // Append the newly created element
    this.recurrencyFieldsTarget.appendChild(clone)

    // Fix duplication input date when loadFlatpickr() by deleting them after loading
    $(`#booking_date-${this.counter}`).parent()[0].lastElementChild.remove()
    $(`#booking_ending_date-${this.counter}`).parent()[0].lastElementChild.remove()

    // Set value to nil by default
    $(`#booking_time-${this.counter}`)[0].value = ''
    $(`#booking_ending_time-${this.counter}`)[0].value = ''

    // undisplayed 'form-field--error' class when loadFlatpickr()
    $('.recurrence').each(function () {
      const resultInputs = $(this)[0].getElementsByTagName('input')
      for (const input of resultInputs) {
        if (input.classList.contains('form-field--error')) {
          input.classList.remove('form-field--error')
        }
      }
    })

    this.counter++
  }

  deleteField(event) {
    if (event.target.closest('.recurrence-forms').childElementCount > 1) {
      event.target.closest('.recurrence').remove()
    }

    if ($('.recurrence-forms')[0].childElementCount === 1) {
      $('.recurrence')[0].getElementsByTagName('span')[1].classList.add('disabled')
    }
  }

  setAttributes(el, attrs) {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key])
    }
  }
}
