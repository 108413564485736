import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fields', 'slotField', 'hiddenInput', 'input']
  static values = { template: String }

  connect() {
    this.templateValue = this.slotFieldTarget.innerHTML
  }

  addField(event) {
    event.preventDefault()
    const findClones = document.querySelectorAll('.recurrence-slot-fields')
    findClones.forEach(clone => clone.classList.contains('clone') && clone.classList.remove('clone'))
    const slotFields = document.createElement('div')
    slotFields.classList.add('recurrence-slot-fields', 'mb-3', 'clone')
    slotFields.setAttribute('data-recurrence-ga-fields-target', 'slotField')
    slotFields.innerHTML = this.templateValue
    const slotFieldsLastChild = slotFields.querySelector('.input input:last-child')
    slotFieldsLastChild.remove()
    slotFieldsLastChild.value = ''
    this.fieldsTarget.appendChild(slotFields)
    this.initDatepicker()
  }

  setInputValue() {
    this.hiddenInputTarget.value = JSON.stringify(
      this.slotFieldTargets.map(inputs => [...inputs.querySelectorAll('[data-recurrence-ga-fields-target="input"]')].map(input => input.value))
    )
  }

  removeField(event) {
    event.preventDefault()

    event.target.closest('.recurrence-slot-fields').remove()
    this.setInputValue()
  }

  initDatepicker() {
    const flatpickController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('#new_edit_booking_form'),
      'forms--flatpickr'
    )
    flatpickController.connect()
  }
}
