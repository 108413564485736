import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['accordeonButton']

  connect() {
    this.initializeToggleAccordeon()
  }

  /**
   * Initialize all switch toggle buttons
   */
  initializeToggleAccordeon() {
    if (this.hasAccordeonButtonTarget) {
      this.accordeonButtonTargets.forEach(button => {
        button.addEventListener('click', this.handleClick)
      })
    }
  }

  handleClick(e) {
    e.currentTarget.classList.toggle('accordeon__item--active')
    const parentsAccordeon = e.currentTarget.parentNode.querySelectorAll('.js-accordeon-revealed')
    parentsAccordeon.forEach(elem => elem.classList.toggle('active'))
  }
}
