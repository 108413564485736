import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['inputs', 'submitBtn', 'errorMessages']

  emailRegex = /(.+)@(.+){2,}\.(.+){2,}/

  initialize() {
    this.validate()
  }

  setIsValid() {
    if (this.isValid) {
      this.submitBtnTarget.removeAttribute('disabled')
    } else {
      this.submitBtnTarget.setAttribute('disabled', true)
    }
  }

  validate() {
    this.isValid = true
    this.inputsTargets.forEach(input => {
      const fieldName = /\W(?<value>.*)\W/g.exec(input.name).groups.value
      const passwordField = this.inputsTargets.find(elem => elem.classList.contains('password'))
      switch (fieldName) {
        case 'email':
          this.displayError('#emailError', this.emailRegex.test(input.value))
          break
        case 'password':
          this.displayError('#passwordLenghtError', input.value.length >= 12)
          this.displayError('#passwordUppercaseError', input.value.match(/[A-Z]/))
          this.displayError('#passwordSpecialCharacterError', input.value.match(/[\W]/))
          break
        case 'password_confirmation':
          this.displayError('#passwordConfirmationError', input.value === passwordField.value)
          break
      }
    })
    this.setIsValid()
  }

  displayError(id, validate) {
    if (validate) {
      this.errorMessagesTarget.querySelector(`${id} .valid`).removeAttribute('hidden')
      this.errorMessagesTarget.querySelector(`${id} .invalid`).setAttribute('hidden', true)
    } else {
      this.errorMessagesTarget.querySelector(`${id} .valid`).setAttribute('hidden', true)
      this.errorMessagesTarget.querySelector(`${id} .invalid`).removeAttribute('hidden')
      this.isValid = false
    }
  }
}
