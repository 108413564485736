import { Controller } from 'stimulus'
import { ManageAvailabilitiesCalendar } from '../../libs/manage_availability_calendar.js'
export default class extends Controller {
  static targets = [
    'availablesDatesInfos',
    'availableRadio',
    'blockedRadio',
    'errorMessage',
    'radioBtn',
    'selectedAndDisabled',
    'selectedAndAvailable',
    'selectContainerPattern',
    'selectPattern',
    'textAreaPattern',
    'tooltip',
    'tooltipPattern'
  ]
  manageAvailabilities = ''

  connect() {}

  dispatchUnavailabilitiesInfos(target) {
    const pattern = target.querySelector('.tooltip-pattern')
    if (target.dataset.reason === 'Autre' && target.dataset.description !== '') {
      pattern.innerText = target.dataset.description
    } else {
      pattern.innerText = target.dataset.reason
    }
  }

  hidePatternsSelect() {
    this.textAreaPatternTarget.classList.add('hidden')
    this.selectContainerPatternTarget.classList.add('hidden')
  }

  manageStatesRadiosBtns(cellSelectedAndDisabled, cellSelectedAndAvailable) {
    if (cellSelectedAndDisabled > 0 && cellSelectedAndAvailable === 0) {
      this.blockedRadioTarget.checked = true
      this.availableRadioTarget.checked = false
      this.selectContainerPatternTarget.classList.remove('hidden')
    } else if (cellSelectedAndDisabled > 0 && cellSelectedAndAvailable > 0) {
      this.blockedRadioTarget.checked = false
      this.availableRadioTarget.checked = false
    } else {
      this.availableRadioTarget.checked = true
    }

    this.selectedAndDisabledTargets.forEach(cell => {
      cell.removeAttribute('data-forms--manage-availability-target')
    })

    this.selectedAndAvailableTargets.forEach(cell => {
      cell.removeAttribute('data-forms--manage-availability-target')
    })
  }

  onChangeCheckAvailabilities(event) {
    const eventTarget = event.target
    let availabilitiesDatas = []
    const disabledDatesOthers = [...document.querySelectorAll('td.is-blocked-other')]
    disabledDatesOthers.forEach(day => {
      let endDate = new Date(day.dataset.date).setHours(0, 59, 59, 0)
      const availabilityDate = {
        startDate: new Date(day.dataset.date).toISOString(),
        endDate: new Date(endDate).toISOString(),
        description: day.dataset.description
      }
      availabilitiesDatas.push(availabilityDate)
    })
    this.manageAvailabilities = new ManageAvailabilitiesCalendar(availabilitiesDatas, {})
    const allDates = [...document.querySelectorAll('[data-date]')]
    const disabledDates = [...document.querySelectorAll('td.is-unavailable')]
    if (eventTarget._flatpickr.selectedDates.length > 1) {
      let newStartDate = new Date(eventTarget._flatpickr.selectedDates[0])
      newStartDate.setDate(newStartDate.getDate() + 1)
      newStartDate = newStartDate.toISOString().split('T')[0]
      let newEndDate = new Date(eventTarget._flatpickr.selectedDates[1])
      newEndDate.setDate(newEndDate.getDate() + 1)
      newEndDate = newEndDate.toISOString().split('T')[0]
      eventTarget.dataset.startDate = newStartDate
      eventTarget.dataset.endDate = newEndDate
      this.manageAvailabilities.checkDateAvailableOrNot(newStartDate, newEndDate, allDates)
    }
  }

  otherPattern() {
    this.textAreaPatternTarget.firstChild.value = ''
    this.errorMessageTarget.classList.add('hidden')
    if (this.selectPatternTarget.lastChild.selected === true) {
      this.textAreaPatternTarget.classList.remove('hidden')
    } else {
      this.textAreaPatternTarget.classList.add('hidden')
    }
  }

  showPatternsSelect() {
    this.selectPatternTarget.value = 'under_construction'
    this.selectContainerPatternTarget.classList.remove('hidden')
    this.errorMessageTarget.classList.add('hidden')
  }

  tooltip(event) {
    let currentDate = event.currentTarget
    this.dispatchUnavailabilitiesInfos(currentDate)
    currentDate.querySelector('.tooltip').classList.add('fade-in')
  }

  tooltipHide(event) {
    event.currentTarget.querySelector('.tooltip').classList.remove('fade-in')
  }

  unChecked() {
    this.selectContainerPatternTarget.classList.add('hidden')
    this.errorMessageTarget.classList.add('hidden')
    this.radioBtnTargets.forEach(radio => {
      radio.checked = false
    })
    let cellSelectedAndDisabled = this.selectedAndDisabledTargets.length
    let cellSelectedAndAvailable = this.selectedAndAvailableTargets.length
    if (cellSelectedAndDisabled > 0 && cellSelectedAndAvailable > 0) {
      this.availablesDatesInfosTarget.classList.remove('hidden')
    } else {
      this.availablesDatesInfosTarget.classList.add('hidden')
    }
    this.manageStatesRadiosBtns(cellSelectedAndDisabled, cellSelectedAndAvailable)
  }
}
