import { Controller } from 'stimulus'
import FlatpickrController from './forms/flatpickr_controller'

export default class extends Controller {
  static targets = [
    'boForm',
    'errorMsgNumber',
    'canceledBtn',
    'cardSkeletton',
    'cardsList',
    'catchValue',
    'dataCardComponent',
    'errorMsg',
    'form',
    'formSkeletton',
    'inputLastForm',
    'inputModal',
    'inputNumber',
    'modalContent',
    'modalForm',
    'radioBtn'
  ]

  static values = {
    nestedModel: String,
    model: String
  }

  currentFormIndex = null
  valueInputs = []
  valueInputsNumber = []
  checkboxCheckedInputs = []
  fullCatchValuesArray = []
  radioArray = []

  connect() {
    this.dataCardComponentTargets.forEach((element, index) => {
      element.querySelector('.index-code').innerText = ` ${index + 1}`
    })
    this.formTargets.forEach((element, index) => {
      element.setAttribute('data-index', index + 1)
    })
    const card = this.dataCardComponentTargets.find(element => !element.querySelector('#label-type').innerText.trim().length)
    if (card !== undefined && card.dataset.index.length) card.remove()
  }

  editForm(event) {
    this.errorMsgTargets.forEach(error => {
      error.classList.add('d-none')
    })

    const cardIndex = event.currentTarget.parentNode.dataset.index
    this.currentFormIndex = cardIndex
    this.valueInputs = []
    this.checkboxCheckedInputs = []
    this.formTargets.forEach(form => {
      if (form.dataset.index === cardIndex) {
        form.classList.remove('d-none')
        const allInputs = form.querySelectorAll('input')
        allInputs.forEach(input => {
          this.valueInputs.push(input.value)
          if (input.checked === true) {
            this.checkboxCheckedInputs.push(input)
          }
        })
        const indexCurrentForm = form.dataset.index
        form.closest('.modal-box').querySelector('.modal-box__cross a').setAttribute('data-index', indexCurrentForm)
      } else {
        form.classList.add('d-none')
      }
    })
    this.canceledBtnTargets.forEach(btnCancel => {
      if (btnCancel.dataset.index === cardIndex) {
        btnCancel.removeAttribute('data-action')
        btnCancel.setAttribute('data-action', 'modal-hideform-gencard#canceledEdit')
      }
    })
  }

  canceledModal() {
    this.formTargets[this.formTargets.length - 1].remove()
    this.modalContentTarget.classList.add('d-none')
    document.querySelector('[data-modal-target="subModal"]').classList.remove('overflow--hidden')
  }

  canceledEdit(event) {
    const btnIndex = event.currentTarget.dataset.index
    this.formTargets.forEach(form => {
      if (form.dataset.index === btnIndex) {
        const allInputs = form.querySelectorAll('input')
        const allRadios = form.querySelectorAll('.form__row-radio')
        allInputs.forEach((input, index) => {
          input.value = this.valueInputs[index]
          if (this.checkboxCheckedInputs.includes(input)) {
            input.checked = true
          } else {
            input.checked = false
          }
        })

        if(this.radioBtnTargets.length) {
          this.radioBtnTargets.forEach(radio => {
            if(radio.closest('[data-massage-practitioner-form-target="formMassage"]').dataset.index === btnIndex) {
              this.radioArray.push(radio)
            }
          })
  
          this.radioArray.forEach(radio => {
            radio.closest('.form__row-radio').classList.remove('active')
            if(radio.checked === true) {
              radio.closest('.form__row-radio').classList.add('active')
            }
          })
          this.radioArray = []
        }
      }
    })
    this.modalContentTarget.classList.add('d-none')
    document.querySelector('[data-modal-target="subModal"]').classList.remove('overflow--hidden')
  }

  sendFormModal() {
    this.modalContentTarget.classList.add('d-none')
  }

  atLeastOneCheckboxChecked(checkboxes, valid) {
    if (!checkboxes.length) return true
    const checkboxChecked = checkboxes.some(checkbox => checkbox.checked)
    if (!checkboxChecked) {
      checkboxes[0].closest('.checkbox-two-columns-wrapper').parentNode.querySelector('span').classList.remove('d-none')
      return false
    }
    return true
  }

  verifyFormModal(event) {
    event.preventDefault()
    this.errorMsgTargets.forEach(error => {
      error.classList.add('d-none')
    })
    const currentForm = this.formTargets.find(elem => elem.dataset.index === event.currentTarget.dataset.index)
    const indexForm = currentForm.dataset.index
    let valid = true
    const inputNeedsToVerify = currentForm.querySelectorAll('input.required')
    const selectNeedsToVerify = currentForm.querySelectorAll('select.required')
    const checkboxesNeedsToVerify = currentForm.querySelectorAll('input.required-checkboxes')
    valid = this.atLeastOneCheckboxChecked([...checkboxesNeedsToVerify], valid)
    inputNeedsToVerify.forEach(input => {
      if (input.type !== 'hidden' && input.value === '') {
        input.closest('.form__field-content').querySelector('span').classList.remove('d-none')
        valid = false
      } else {
        input.closest('.form__field-content').querySelector('span').classList.add('d-none')
      }
    })
    selectNeedsToVerify.forEach(select => {
      const noValueChoice = select[0].value
      if (select.value === noValueChoice) {
        select.closest('.form__field-content').querySelector('span').classList.remove('d-none')
        valid = false
      }
    })

    if (valid) {
      this.setInputName(currentForm)
      this.upsertCard(currentForm)
      currentForm.classList.add('d-none')
      this.modalContentTarget.classList.add('d-none')
      document.querySelector('[data-modal-target="subModal"]').classList.remove('overflow--hidden')
      const goToCards = document.getElementById('anchor-cards-add')
      goToCards.scrollIntoView({ behavior: 'instant' }, true)
    }
  }

  setInputName(currentForm) {
    currentForm.querySelectorAll('input, select').forEach(input => {
      if (!input.dataset.inputName && !input.name) return
      if (!input.dataset.inputName && input.name) {
        input.setAttribute('name', input.name.replace(/\d+/g, this.formTargets.length))
        input.value = input.dataset.value || ''
        return
      }
      let name = `${this.modelValue}[${this.nestedModelValue}][${this.formTargets.length}][${input.dataset.inputName}]`
      if (input.dataset.inputName === 'ski_areas') name += '[]'
      input.setAttribute('name', name)
    })
  }

  upsertCard(currentForm) {
    const alreadyExist = this.dataCardComponentTargets.some(card => card.dataset.index === currentForm.dataset.index && currentForm.dataset.index)
    if (alreadyExist) {
      const currentCard = this.dataCardComponentTargets.find(card => card.dataset.index === currentForm.dataset.index && currentForm.dataset.index)
      this.displayCardTitle(currentCard, currentForm)
      currentCard.classList.remove('d-none')
      this.dataCardComponentTargets.forEach((element, index) => {
        element.querySelector('.index-code').innerText = ` ${index + 1}`
      })
    } else {
      const newCard = this.cardSkelettonTarget.firstElementChild.cloneNode(true)
      newCard.setAttribute('data-index', this.formTargets.length)
      this.displayCardTitle(newCard, currentForm)
      this.cardsListTarget.lastElementChild.insertAdjacentElement('beforeBegin', newCard)
      newCard.classList.remove('d-none')
      this.dataCardComponentTargets.forEach((element, index) => {
        element.querySelector('.index-code').innerText = ` ${index + 1}`
      })
    }
  }

  displayCardTitle(cardElement, currentForm) {
    let titleCard = [...currentForm.querySelectorAll('[data-modal-hideform-gencard-target]')].map(input => input.value).join(' ')
    cardElement.querySelector('.fullname').innerText = titleCard
  }

  cloneForm() {
    const totalForm = this.formTargets.length
    if (totalForm) {
      const lastForm = this.formTargets[totalForm - 1]
      const clone = lastForm.cloneNode(true)
      clone.querySelectorAll('input, textarea').forEach(input => {
        if (input.dataset.inputType === 'hidden') return
        input.checked = false
        input.value = ''
      })
      this.modalFormTarget.insertAdjacentElement('beforeend', clone)
      clone.setAttribute('data-index', `${parseInt(totalForm) + 1}`)
      clone.querySelector('#save_btn').setAttribute('data-index', `${totalForm + 1}`)
      clone.querySelector('#cancel_btn').setAttribute('data-index', `${totalForm + 1}`)
      this.formTargets.forEach((element, index) => {
        element.classList.add('d-none')
      })
      clone.classList.remove('d-none')
      clone.classList.add('cloned')
      this.currentFormIndex = totalForm
      clone.querySelectorAll('[data-forms--flatpickr-target]').forEach(input => input?.nextElementSibling?.remove())
      this.initDatepicker()
      this.initSelectUi()
      // Fixed tom select behavior
      if (clone.querySelector('.ts-wrapper')) {
        clone.querySelector('.ts-wrapper:not(.tomselected)').classList.add('d-none')
        clone.querySelector('.ts-wrapper.tomselected').classList.remove('ts-hidden-accessible')
      }
      clone.querySelectorAll('.tomselected.input-hidden').forEach(input => input.classList.add('d-none'))
    }
    let btnCanceledArray = [...this.canceledBtnTargets]
    btnCanceledArray[btnCanceledArray.length - 1].setAttribute('data-action', 'modal-hideform-gencard#canceledModal')
    btnCanceledArray[btnCanceledArray.length - 1]
      .closest('.modal-box')
      .querySelector('.modal-box__cross a')
      .setAttribute('data-action', 'modal-hideform-gencard#canceledModal')
  }

  deleteData(event) {
    const cardHasClicked = event.currentTarget.parentNode
    const response = confirm(`${event.target.closest('span.delete-button').dataset.deleteMessage}`)
    response && cardHasClicked.remove()
    const currentForm = this.formTargets.find(element => element.dataset.index === cardHasClicked.dataset.index)
    if (currentForm.querySelector('#destroy_input') !== null) {
      currentForm.querySelector('#destroy_input').value = true
    }
    this.dataCardComponentTargets.forEach((element, index) => {
      element.querySelector('.index-code').innerText = ` ${index + 1}`
    })
  }

  initDatepicker() {
    const flatpickController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('#new_edit_booking_form'),
      'forms--flatpickr'
    )
    flatpickController.connect()
  }

  initSelectUi() {
    const selectUiController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('#new_edit_booking_form'),
      'forms--select-ui'
    )
    selectUiController.connect()
  }
}
