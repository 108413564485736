import { Controller } from '@hotwired/stimulus'

// Change order of GuestAppCategories list in partials/services/_services_table
export default class extends Controller {
  static values = {
    id: Number,
    order: Number,
    count: Number,
    hotelid: String
  }

  connect() {}

  upOrder() {
    if (this.orderValue === 1) return
    const newOrder = this.orderValue - 1
    this.toggleCategoriesElements(newOrder)
    this.update(newOrder)
  }

  downOrder() {
    if (this.countValue === this.orderValue) return
    const newOrder = this.orderValue + 1
    this.toggleCategoriesElements(newOrder)
    this.update(newOrder)
  }

  toggleCategoriesElements(newOrder) {
    const currentCategoryElement = document.querySelector(`[data-service-order-order-value='${this.orderValue}']`)
    const oldCategoryElement = document.querySelector(`[data-service-order-order-value='${newOrder}']`)
    oldCategoryElement.dataset.serviceOrderOrderValue = this.orderValue
    currentCategoryElement.dataset.serviceOrderOrderValue = newOrder
    oldCategoryElement.outerHTML = `${currentCategoryElement.outerHTML}`
    currentCategoryElement.outerHTML = `${oldCategoryElement.outerHTML}`
  }

  update(order) {
    const params = new URLSearchParams({ order: order })
    let url = `/hotels/${this.hotelidValue}/experience/guest_app_categories/${this.idValue}/change_order?${params.toString()}`
    fetch(url).catch(err => window.location.reload())
  }
}
