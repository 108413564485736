async function janaHttpFetch(endpoint, options) {
  const url = `/api/${endpoint}`
  const headers = {
    'Content-Type': 'application/json'
    // Add any required headers for the API here
    // For example: 'Authorization': `Bearer ${this.apiKey}`
  }
  const response = await fetch(url, { ...options, headers })
  if (!response.ok) {
    throw new Error(`Fetch error: ${response.status}`)
  }
  return response.json()
}

export { janaHttpFetch }
