import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['requiredInput', 'inputContainer']

  valid = null

  connect() {}

  checkFormWithoutLib() {
    this.requiredInputTargets.forEach((required, index) => {
      const errorMsg = this.inputContainerTargets[index].querySelector('#requiredInputMsg')
      if (required.value === '') {
        errorMsg.classList.remove('tw-hidden')
      } else {
        errorMsg.classList.add('tw-hidden')
      }
    })
  }

  checkValueSupToZero(e) {
    const currentInput = e.currentTarget
    const errorMsg = currentInput.closest('[data-modal-form-check-target="inputContainer"]').querySelector('#valueMinNumberMsg')
    const minNumber = Number(currentInput.dataset.minNumber) || 0
    currentInput.value < minNumber ? errorMsg.classList.remove('tw-hidden') : errorMsg.classList.add('tw-hidden')
  }

  checkModalValid(e) {
    const allErrorMsg = document.querySelectorAll('.error-msg')
    let verifyAllErrorMsg = []
    allErrorMsg.forEach(msg => {
      if (msg.classList.contains('tw-hidden')) verifyAllErrorMsg.push(msg)
    })
    if (allErrorMsg.length === verifyAllErrorMsg.length) {
      e.currentTarget.closest('.popup__item').classList.remove('overflow--hidden')
      e.currentTarget.closest('.popup__container').classList.add('d-none')
      verifyAllErrorMsg = []
    }
  }
}
