import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import BaseCalendarController from './base_calendar_controller'
import Kitsu from 'kitsu'
import formatDate from '../../utils/formatDateCalendar'

export default class extends BaseCalendarController {
  static targets = ['calendarContainer', 'form', 'loader']
  static values = {
    filterDate: String,
    filterKind: String,
    filterUnassigned: String,
    filterUsers: Array
  }

  connect() {
    super.connect()
    this.users = this.filterUsersValue
    this.isInitialRender = true
    this.currentDate = this.filterDateValue
    document.querySelector('#unassignedButton').classList.remove('d-none')
    this.updateTaskUrl = this.updateTaskUrlValue
    this.initCalendar()
  }

  initCalendar() {
    const calendarEl = this.calendarContainerTarget

    if (calendarEl) {
      const initialDate = this.firstDayOfWeek()
      const options = {
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        views: {
          timeGridWeek: {
            type: 'timeGrid',
            duration: { days: 7 },
            buttonText: 'Semaine'
          }
        },
        initialDate,
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        locale: 'locale',
        events: async ({ startStr, endStr }, successCallback, failureCallback) => {
          this.loaderTarget.style.display = 'flex'
          const newTasks = await this.updateCurrentData(this.formatDateRequest(startStr, endStr))
          successCallback(newTasks)
          setTimeout(() => {
            this.loaderTarget.style.display = 'none'
          }, 1000)
        },
        stickyHeaderDates: true,
        customButtons: {
          weeklyButton: {
            text: 'Semaine',
            click: () => {}
          },
          dailyButton: {
            text: 'Jour',
            click: () => {
              this.handleChangeView('daily_calendar')
            }
          }
        },
        handleWindowResize: true,
        headerToolbar: {
          left: 'weeklyButton,dailyButton',
          right: ''
        },
        slotMinTime: '00:00:00',
        slotMaxTime: '24:00:00',
        scrollTime: '06:00:00',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false
        },
        slotDuration: '00:15:00',
        slotLabelInterval: '01:00:00',
        allDaySlot: true,
        allDayContent: arg => {
          const container = document.createElement('p')
          container.innerHTML = 'Sans heure'
          container.classList.add('whithout-hour')
          return { domNodes: [container] }
        },
        dayHeaderFormat: { weekday: 'long' },
        dayHeaderContent: this.dayHeaderContent.bind(this),
        eventContent: this.renderEventContent.bind(this),
        eventClick: this.eventClick.bind(this),
        editable: true,
        eventDrop: this.handleTimeChange.bind(this),
        eventResize: this.handleDurationChange.bind(this),
        height: 'auto',
        stickyHeaderDates: true
      }
      const calendar = new Calendar(calendarEl, options)
      calendar.render()
      this.addCustomButtonClasses()
    }
  }

  formatDateRequest(start, end) {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const startFormatted = formatDate(startDate)
    const endFormatted = formatDate(endDate)
    return { start: startFormatted, end: endFormatted }
  }

  async updateCurrentData(dates) {
    const newTasks = await this.fetchCurrentTasks(dates, this.filterUnassignedValue)
    this.updateUnassignedCounter(newTasks)
    return newTasks.map(task => {
      const { start, allDay } = this.formatDate(task)
      return this.buildCalendarEvent(task, start, allDay)
    })
  }

  updateUnassignedCounter(newTasks) {
    console.log(newTasks)
    const unassignedTasks = newTasks.filter(task => task.taskRequests.data.length < 1)
    document.querySelector('#task-unassigned-counter').textContent = unassignedTasks.length
  }

  async fetchCurrentTasks({ start, end }, unassigned) {
    const filter = { start_date: start, end_date: end }
    if (unassigned == 'true') {
      filter.unassigned = true
    }
    console.log(this.users)
    if (this.users.length > 0) {
      filter['users[]'] = this.users;
    }
    if (this.filterKindValue) {
      filter.kind = this.filterKindValue
    }
    const fetchApi = new Kitsu({
      baseURL: '/api'
    })

    let allTasks = []
    let offset = 0
    let fetchedData

    do {
      const { data } = await fetchApi.get('tasks', {
        params: {
          filter,
          include: 'housing,taskRequests.user',
          page: {
            offset: offset,
            limit: 100
          }
        }
      })
      fetchedData = data
      allTasks = allTasks.concat(fetchedData)
      offset += 100
    } while (fetchedData.length === 100) // If less than 100 items are fetched, it means we've reached the last page

    return allTasks
  }

  firstDayOfWeek(date = new Date(this.filterDateValue)) {
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
  }

  updateUrlWithDate(date) {
    if (this.isInitialRender) {
      this.isInitialRender = false
      return
    }
    const url = new URL(window.location.href)
    document.getElementById('sidebarDate')._flatpickr.setDate(date)
    const [day, month, year] = date.toLocaleDateString().split('/')
    const newDate = `${year}-${month}-${day}`
    url.searchParams.set('date', newDate)
    history.pushState(null, '', url)
    this.updateHrefDate(url, newDate)
  }

  dayHeaderContent({ date }) {
    return {
      html: `${date.toLocaleDateString(this.locale, { weekday: 'long' })}<strong>${date.toLocaleDateString(this.locale, {
        day: 'numeric'
      })}</strong>`
    }
  }

  addCustomButtonClasses() {
    const weeklyButton = document.querySelector('.fc-weeklyButton-button')
    const dailyButton = document.querySelector('.fc-dailyButton-button')
    weeklyButton.classList.add('active')
    dailyButton.classList.add('inactive')
  }
}
