import { janaHttpFetch } from './api'

class Booking {
  static async form(serviceId, vendorCategoryId) {
    const queryParams = new URLSearchParams()
    if (serviceId) queryParams.append('service_id', serviceId)
    if (vendorCategoryId) queryParams.append('vendor_category_id', vendorCategoryId)
    return janaHttpFetch(`bookings/form?${queryParams.toString()}`)
  }
}

export { Booking }
