import { Controller } from 'stimulus'
import JustValidate from 'just-validate'

export default class extends Controller {
  static targets = [
    'email',
    'errorContainer',
    'errorContainerDate',
    'errorContainerGroup',
    'errorContainerNumber',
    'errorEmailContainer',
    'errorMessage',
    'number',
    'minNumber',
    'minLength',
    'required',
    'requiredInput',
    'inputContainer',
    'requiredInputContainerMsg',
    'requiredDate',
    'requiredEmail',
    'requiredRadioGroup',
    'valueNotZero',
    'valueNotZeroMsg'
  ]

  valid = null

  connect() {
    let guestForm = this.element
    let fields = this.fieldsTargets
    let globalConfig = {
      errorFieldCssClass: ['fields-error'],
      errorLabelStyle: {
        color: '#EE5F5B'
      },
      successFieldCssClass: ['valid']
    }
    this.valid = new JustValidate(guestForm, globalConfig)
  }

  checkForm() {
    this.emailTargets.forEach((input, index) => {
      this.valid.addField(
        input,
        [
          {
            rule: 'email',
            errorMessage: input.dataset.errorMsg
          }
        ],
        {
          errorsContainer: this.errorEmailContainerTargets[index]
        }
      )
    })

    this.numberTargets.forEach((input, index) => {
      this.valid.addField(
        input,
        [
          {
            rule: 'number',
            errorMessage: input.dataset.errorMsg
          }
        ],
        {
          errorsContainer: this.errorContainerNumberTargets[index]
        }
      )
    })

    this.requiredEmailTargets.forEach((input, index) => {
      this.valid.addField(
        input,
        [
          {
            rule: 'required',
            errorMessage: input.dataset.errorMsg
          },
          {
            rule: 'email',
            errorMessage: input.dataset.errorMsgEmail
          }
        ],
        {
          errorsContainer: this.errorContainerTargets[index]
        }
      )
    })
    this.requiredTargets.forEach((input, index) => {
      this.valid.addField(
        input,
        [
          {
            rule: 'required',
            errorMessage: input.dataset.errorMsg
          }
        ],
        {
          errorsContainer: this.errorContainerTargets[index]
        }
      )
    })

    this.requiredDateTargets.forEach((input, index) => {
      this.valid.addField(
        input.nextElementSibling,
        [
          {
            rule: 'required',
            errorMessage: input.dataset.errorMsg
          }
        ],
        {
          errorsContainer: this.errorContainerDateTargets[index]
        }
      )
    })

    this.requiredRadioGroupTargets.forEach((radios, index) => {
      this.valid.addRequiredGroup(radios, radios.dataset.errorMsg, {
        errorsContainer: this.errorContainerGroupTargets[index]
      })
    })

    this.minLengthTargets.forEach((input, index) => {
      this.valid.addField(
        input,
        [
          {
            rule: 'required',
            errorMessage: input.dataset.errorMsg
          },
          {
            rule: 'minLength',
            value: Number(input.dataset.minLengthNumber),
            errorMessage: input.dataset.errorMsgLength
          }
        ],
        {
          errorsContainer: this.errorContainerTargets[index]
        }
      )
    })

    this.minNumberTargets.forEach((input, index) => {
      this.valid.addField(
        input,
        [
          {
            rule: 'minNumber',
            value: Number(input.dataset.minNumber),
            errorMessage: input.dataset.errorMinNumber
          }
        ],
        {
          errorsContainer: this.errorContainerTargets[index]
        }
      )
    })

    this.valid.onSuccess(event => {
      this.element.submit()
    })

    this.valid.onFail(fields => {})
  }
}
