import { Controller } from 'stimulus'
import { ManageAvailabilitiesCalendarBo } from '../../libs/manage_availability_calendar_bo.js'

export default class extends Controller {
  static targets = ['cellUnavailable', 'ctaAvailableForm']
  dateCells = []
  connect() {}

  emptySelectize(e) {
    const containerForm = e.currentTarget.closest('#newPerson')
    const searchGuest = containerForm.querySelector('.selectized')
    searchGuest.selectize.clear()
  }

  openAvailableForm() {
    document.querySelectorAll('.fc-timeline-bg-harness .planning-event-title').forEach(cell => {
      this.dateCells.push(cell.dataset.date)
    })
    const manageAvailabilities = new ManageAvailabilitiesCalendarBo(this.dateCells)
    manageAvailabilities.getParentsUnavaiblesCells()
  }
}
