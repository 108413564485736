import { Controller } from 'stimulus'
import { addDnoneClass, hide, removeDnoneClass, show } from '../../../utils/animationMethods'

export default class extends Controller {
  static targets = ['selectLanguageInput', 'scenarioSelectLanguageInput']

  connect() {
    this.initSelectLanguage()
  }

  /**
   * Initialize common select language inputs
   */
  initSelectLanguage() {
    if (this.hasSelectLanguageInputTarget) {
      this.selectLanguageInputTargets.forEach(input => {
        input.tomselect.on('change', value => {
          const language = value
          const inputName = input.dataset.inputName
          const inputFr = document.querySelector(`#${inputName}_fr`)
          const inputEn = document.querySelector(`#${inputName}_en`)
          if (language === 'french') {
            if (inputEn) addDnoneClass(inputEn)
            if (inputFr) removeDnoneClass(inputEn)
          } else {
            if (inputEn) removeDnoneClass(inputEn)
            if (inputFr) addDnoneClass(inputFr)
          }
        })
      })
    }
  }

  /**
   *  Initialize scenarios languages inputs
   */
  InitScenarios() {
    if (this.hasScenarioSelectLanguageInputTarget) {
      this.scenarioSelectLanguageInputTarget.tomselect.on('change', value => {
        const objectFr = this.scenarioObjectFRTarget
        const objectEn = this.scenarioObjectEnTarget
        const contentFr = this.scenarioContentFrTarget
        const contentEn = this.scenarioContentEnTarget
        if (value === 'french') {
          if (objectEn) hide(objectEn)
          if (objectFr) show(objectFr)
          hide(contentEn)
          show(contentFr)
        } else {
          if (objectEn) show(objectEn)
          if (objectFr) hide(objectFr)
          show(contentEn)
          hide(contentFr)
        }
      })
    }
  }
}
