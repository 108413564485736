import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    console.log('hello form partial')
  }

  updateFormPartial(e) {
    e.preventDefault()
    console.log('updateformpartial')
    console.log(this.createUrl(e.target.dataset, { [e.target.name]: e.target.value }))
    const { url } = e.target.dataset

    this.element.src = url
  }

  createUrl(path, args) {
    return `${path}?${new URLSearchParams(args)}`
  }
}
