import { Controller } from 'stimulus'
import { checkbox } from '../../app'

export default class extends Controller {
  static values = { id: Number }
  static targets = ['indexTasks', 'indexTasksActionBar', 'datepicker', 'dashboard', 'dashboardStats']

  connect() {
    this.channel = App.cable.subscriptions.create({ channel: 'BackOfficeChannel', id: this.idValue }, { received: data => this.checkDataModel(data) })
  }

  checkDataModel(data) {
    if (data.task) {
      this.renderTaskPartial(data)
    } else if (data.owner) {
      this.renderGuestOrOwnerModulePartial(data, `#module_owner_${data.owner.id}`)
    } else if (data.guest) {
      this.renderGuestOrOwnerModulePartial(data, `#module_guest_${data.guest.id}`)
    } else if (data.notification) {
      this.renderNotificationPartial(data)
    }
  }

  renderNotificationPartial(data) {
    // Partials
    const { notification, user, moduleData, unreadCount } = data

    // BACK-OFFICE MODULE
    const moduleNotificationElement = document.querySelector('#module_notifications')
    let userId = null
    if (moduleNotificationElement) {
      userId = parseInt(moduleNotificationElement.dataset.user)
    }

    if (moduleNotificationElement && moduleData && user && userId === user.id) {
      moduleNotificationElement.parentNode.innerHTML = moduleData
      const unreadNotificationCount = document.querySelector('#notifications-unread-count')
      if (unreadNotificationCount) {
        unreadNotificationCount.textContent = unreadCount
        if (unreadCount === 0) {
          unreadNotificationCount.parentNode.classList.add('d-none')
        } else {
          unreadNotificationCount.parentNode.classList.remove('d-none')
        }
      }
    }
  }

  renderGuestOrOwnerModulePartial(data, elementId) {
    // Partials
    const { guest, user, module_data, owner } = data

    // BACK-OFFICE MODULE
    const moduleElement = document.querySelector(elementId)
    let userId = null
    if (moduleElement) {
      userId = parseInt(moduleElement.dataset.user)
    }

    if (moduleElement && module_data && user && userId === user.id) {
      moduleElement.parentNode.innerHTML = module_data
      loadFlatpickrExpenseRange()
      loadFlatpickr()
      switchToggleTrueFalse()
      loadSelectize()
      const tabExpense = document.querySelector('#tab-expenses')
      if (tabExpense) {
        tabExpense.click()
      }
    }
  }

  renderTaskPartial(data) {
    // Partials
    const { task, module_data, last_date } = data

    // BACK-OFFICE MODULE
    const moduleTaskElement = document.querySelector(`#module_task_${task.id}`)
    if (moduleTaskElement && module_data) {
      moduleTaskElement.parentNode.innerHTML = module_data
    }
    // BACK-OFFICE INDEX LIST/COLUMNS
    if (this.hasIndexTasksTarget) {
      // filters
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.set('task_date', task.date)

      const taskElement = document.querySelector(`#task_${task.id}`)
      const tableElement = document.querySelector(`[data-date='${task.date}'].task_table`)
      const lastTask = document.querySelector('.taskTable')
      lastTask ? urlParams.set('last_date', lastTask.dataset.date) : urlParams.delete('last_date')
      // render new element
      if (tableElement) {
        let url = `/api/v1/render_tasks_partial?${urlParams.toString()}`
        this.getIndexPartial(url).then(partial => {
          tableElement.innerHTML = partial.tasks
          DeleteModalConfirmation()
          CancelModalConfirmation()
          checkbox()
          window.tooltip()
          if (this.hasIndexTasksActionBarTarget) {
            this.indexTasksActionBarTarget.outerHTML = partial.unassigned
          }
        })
      }
      // render old element
      if (last_date) {
        const lastTableElement = document.querySelector(`[data-date='${last_date}'].task_table`)
        urlParams.set('task_date', last_date)
        let url = `/api/v1/render_tasks_partial?${urlParams.toString()}`
        this.getIndexPartial(url).then(partial => {
          lastTableElement.innerHTML = partial.tasks
          DeleteModalConfirmation()
          CancelModalConfirmation()
          checkbox()
          window.tooltip()
        })
      }
    }
    // DASHBOARD
    this.updateDashboard(task)
  }

  updateDashboard(task) {
    if (this.hasDashboardTarget) {
      const urlParams = new URLSearchParams({
        kind: task.kind,
        task_date: task.date
      })
      let url = `/api/v1/render_tasks_partial?${urlParams.toString()}`
      this.getIndexPartial(url).then(res => {
        document.querySelector(`#${task.kind} `).innerHTML = res.tasks
      })
      fetch('/api/v1/render_task_data_dashboard')
        .then(res => res.json())
        .then(data => {
          this.dashboardStatsTarget.outerHTML = data.tasks
        })
    }
  }

  async getIndexPartial(url) {
    let indexPartial = null
    await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(res => res.json().then(data => (indexPartial = data)))
    return indexPartial
  }

  disconnect() {
    this.channel.unsubscribe()
  }
}
