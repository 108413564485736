import { Controller } from 'stimulus'
import { fadeIn, hide } from '../utils/animationMethods'

export default class extends Controller {
  static targets = ['formModalContainer', 'modalContent', 'subModal', 'triggerBtn']
  static values = {}

  openCloseModalForm() {
    fadeIn(this.formModalContainerTarget, 'block', () => {
      this.formModalContainerTarget.querySelector('.form-modal__content').classList.add('form-modal__content--open')
    })

    /* Si boxviu est une modal et est visible, on la hide() */
    const boxVisuModal = document.querySelector('.js-boxvisu-modal')
    if (boxVisuModal) {
      const condition1 = boxVisuModal.style.position === 'absolute'
      const condition2 = boxVisuModal.style.display !== 'none'
      if (condition1 && condition2) {
        hide(document.querySelector('.js-boxvisu-modal-container'))
      }
    }

    /* on évite deux barres de scroll en cachant celle du document */
    document.querySelector('html, body').style.overflowY = 'hidden'
  }

  /*** Ajout d'une méthode pour la modal transport aérien + Forfait ski */
  openModal() {
    this.subModalTarget.classList.add('overflow--hidden')
    this.modalContentTarget.classList.remove('d-none')
    this.subModalTarget.scrollTop = 0
    this.modalContentTarget.scrollTop = 0
  }

  closeModal() {
    this.subModalTarget.classList.remove('overflow--hidden')
    this.modalContentTarget.classList.add('d-none')
  }

  confirmAction() {
    this.triggerBtnTarget.click()
  }
}
