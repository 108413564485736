import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['counter', 'counterAndMsgContainer', 'input', 'errorMessage', 'select']
  static values = { sizes: Object }

  #counter = 0

  connect() {
    this.compute()
  }

  dragstart(event) {
    event.dataTransfer.setData('text', event.target.getAttribute('data-var'))
  }

  dragend() {
    this.focused = this.inputTargets.find(input => input.checkVisibility())
    this.focused.selectionStart = this.focused.selectionEnd
    this.focused.dispatchEvent(new Event('change'))

    this.compute()
  }

  click(event) {
    this.focused = this.inputTargets.find(input => input.checkVisibility())
    let selectionStart = this.focused.selectionStart
    let selectionEnd = this.focused.selectionEnd
    let txt = this.focused.value
    let txtAdded = event.target.getAttribute('data-var')
    let txtChanged = txt.slice(0, selectionStart) + txtAdded + txt.slice(selectionEnd, txt.length)

    this.focused.value = txtChanged
    this.focused.dispatchEvent(new Event('change'))
    this.focused.focus()
    this.focused.setSelectionRange(selectionStart + txtAdded.length, selectionStart + txtAdded.length)

    this.compute()
  }

  compute() {
    const visibleInput = this.inputTargets.find(input => input.checkVisibility())
    const visibleCounter = this.counterTargets.find(counter => counter.checkVisibility())
    const visibleError = this.errorMessageTargets.find(error => error.dataset.locale === this.selectTarget.dataset.locale)

    if (visibleInput && visibleCounter && visibleError) {
      this.#counter = this.#processText(visibleInput.dataset.locale, visibleInput.value)
      visibleCounter.innerText = this.#counter

      if (this.#counter > 350) {
        visibleError.classList.remove('d-none')
        this.counterAndMsgContainerTarget.classList.add('tw-text-red')
      } else {
        visibleError.classList.add('d-none')
        this.counterAndMsgContainerTarget.classList.remove('tw-text-red')
      }
    }
  }

  #processText(locale, text) {
    // Define the regular expression to match keys in the format <key>
    const keyRegex = /<([^>]+)>/g

    // Define a dictionary to map keys to their specific number increments
    const keyIncrements = this.sizesValue[locale]

    // Initialize counters
    let totalCharacters = 0
    let keyCounter = 0

    // Extract keys and replace them in the text to count characters without keys
    let keys = text.match(keyRegex)
    if (keys) {
      keys.forEach(key => {
        let keyName = key.slice(1, -1) // Remove the angle brackets
        if (keyIncrements.hasOwnProperty(keyName)) {
          keyCounter += keyIncrements[keyName]
        }
      })
      totalCharacters = text.replace(keyRegex, '').length
    } else {
      // If no keys are found, the totalCharacters is the length of the text
      totalCharacters = text.length
    }

    return totalCharacters + keyCounter
  }
}
