import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'iconHide', 'iconShow']

  connect() {}

  toggle(event) {
    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text'
      this.iconShowTarget.setAttribute('hidden', true)
      this.iconHideTarget.removeAttribute('hidden')
    } else {
      this.inputTarget.type = 'password'
      this.iconShowTarget.removeAttribute('hidden')
      this.iconHideTarget.setAttribute('hidden', true)
    }
  }
}
