import { Controller } from 'stimulus'
import { fadeIn, slideDown, slideUp, show, hide } from '../utils/animationMethods'

export default class extends Controller {
  static targets = ['vendor', 'guest', 'party', 'guestRequest', 'boxVisuGuest', 'boxVisuParty', 'boxVisuVendor', 'boxVisuConfirmationMessage']
  static values = {
    vendor: { type: String, default: '' },
    guest: { type: String, default: '' },
    party: { type: String, default: '' }
  }

  connect() {
    this.initializeClearBoxVisus()
    this.initializeVendorBoxVisu()
    this.initializePartyBoxVisu()
    this.initializeGuestRequestBoxVisu()
    this.initializeOpenLastBoxVisu()
    this.initializeOpenVendorBoxVisuOnEdit()
    this.initializeOpenPartyBoxVisuOnEdit()
  }

  /**
   * Set the box visu on edit form to trigger the change event which will trigger then
   * the ajax request to get the box visu
   *
   * @param {boolean} condition
   * @param {HTMLElement} target
   * @param {number} valueId
   * @return
   */
  setInitializeBoxVisuOnEdit(target, valueId) {
    if (valueId === '' || valueId === undefined) return
    target.tomselect.trigger('change', valueId)
  }

  /**
   * Set the box visu on form to make the ajax request to get the data of the box visu
   *
   * @param {boolean} condition
   * @param {HTMLElement} target
   * @param {string} boxVisuTarget
   * @param {string} url
   * @param {string} boxVisuToHide
   */
  setInitializeBoxVisu(target, boxVisuTarget, url, boxVisuTargetToHide = '') {
    const hotelSlug = document.querySelector('.booking-form, .instruction-form').dataset.hotelId
    target.tomselect.on('change', value => {
      fadeIn(document.querySelector('.js-form-modal__widgets-bar'))
      show(document.querySelector(boxVisuTarget))

      slideDown(document.querySelector(boxVisuTarget).querySelector('.form-modal__widget__body'))

      document.querySelectorAll('.form-modal__widget').forEach(el => {
        const secondCondition = el.classList.contains(boxVisuTarget.replace('.', ''))
        if (!secondCondition) {
          el.classList.remove('form-modal__widget--open')
          slideUp(el.querySelector('.form-modal__widget__body'))
        }
      })

      if (boxVisuTargetToHide !== '') {
        hide(document.querySelector(boxVisuTargetToHide))
        document.querySelector(boxVisuTargetToHide).classList.remove('form-modal__widget--open')
        slideUp(document.querySelector(boxVisuTargetToHide).querySelector('.form-modal__widget__body'))
      }

      if (value) {
        const urlParsed = `/hotels/${hotelSlug}/` + url.replace(':value', value)
        $.ajax({
          type: 'GET',
          url: urlParsed
        })
      }
    })
  }

  initializeVendorBoxVisu() {
    if (this.hasVendorTarget) {
      this.setInitializeBoxVisu(this.vendorTarget, '.js-form-boxvisu-vendor', 'vendors/:value/form_boxvisu')
    }
  }

  initializeGuestBoxVisu() {
    if (this.hasGuestTarget) {
      this.setInitializeBoxVisu(this.guestTarget, '.js-form-boxvisu-guest', 'service/guests/:value/form_boxvisu', '.js-form-boxvisu-party')
    }
  }

  initializePartyBoxVisu() {
    if (this.hasPartyTarget) {
      this.setInitializeBoxVisu(this.partyTarget, '.js-form-boxvisu-party', 'service/parties/:value/form_boxvisu', '.js-form-boxvisu-guest')
    }
  }

  initializeOpenLastBoxVisu() {
    document.querySelector('.form-modal__widget:not([hidden])').classList.add('form-modal__widget--open')
    slideDown(document.querySelector('.form-modal__widget:not([hidden])').querySelector('.form-modal__widget__body'))
  }

  initializeGuestRequestBoxVisu() {
    if (this.hasGuestRequestTarget) {
      show(this.guestRequestTarget)
    }
  }

  initializeOpenGuestBoxVisuOnEdit() {
    if (this.hasGuestTarget && this.hasGuestValue) {
      this.setInitializeBoxVisuOnEdit(this.guestTarget, this.guestValue)
    }
  }

  initializeOpenVendorBoxVisuOnEdit() {
    if (this.hasVendorTarget && this.hasVendorValue) {
      this.setInitializeBoxVisuOnEdit(this.vendorTarget, this.vendorValue)
    }
  }

  initializeOpenPartyBoxVisuOnEdit() {
    if (this.hasPartyTarget && this.hasPartyValue) {
      this.setInitializeBoxVisuOnEdit(this.partyTarget, this.partyValue)
    }
  }

  /**
   * @param {string[]} selectors
   * @param {string} target
   */
  clearBoxVisu(selectors, target) {
    selectors.forEach(selector => {
      target.querySelector(selector).innerHTML = ''
    })
  }

  initializeClearBoxVisus() {
    document.querySelectorAll('.form-modal__widget').forEach(element => {
      element.classList.remove('form-modal__widget--open')
      hide(element)
    })
    hide(document.querySelector('.js-form-modal__widgets-bar'))

    const guestSelectors = ['.room', '.dates', '.phone', '.mail a']
    const partySelectors = ['.note p']
    const vendorSelectors = ['.map', '.phone', '.web a', '.note p']

    this.clearBoxVisu(guestSelectors, document.querySelector('.js-form-boxvisu-guest'))
    this.clearBoxVisu(partySelectors, document.querySelector('.js-form-boxvisu-party'))
    this.clearBoxVisu(vendorSelectors, document.querySelector('.js-form-boxvisu-vendor'))
  }
}
