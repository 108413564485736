import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['droppable']

  dragstart(event) {
    event.dataTransfer.setData('text', event.target.getAttribute('data-var'))
  }

  dragend() {
    this.focused = this.droppableTargets.find(droppable => droppable.checkVisibility())
    this.focused.selectionStart = this.focused.selectionEnd
    this.focused.dispatchEvent(new Event('change'))
  }

  click(event) {
    this.focused = this.droppableTargets.find(droppable => droppable.checkVisibility())
    let selectionStart = this.focused.selectionStart
    let selectionEnd = this.focused.selectionEnd
    let txt = this.focused.value
    let txtAdded = event.target.getAttribute('data-var')
    let txtChanged = txt.slice(0, selectionStart) + txtAdded + txt.slice(selectionEnd, txt.length)

    if (txtChanged.length < 350) {
      this.focused.value = txtChanged
      this.focused.dispatchEvent(new Event('change'))
      this.focused.focus()
      this.focused.setSelectionRange(selectionStart + txtAdded.length, selectionStart + txtAdded.length)
    }
  }
}
