import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['recurrenceInput', 'recurrenceField', 'recurrenceFieldDate', 'recurrenceFieldNumber']

  verifyInputsDateAndTimes(event) {
    event.preventDefault()
    document
      .querySelector('.recurrence-slot-fields')
      .querySelectorAll('.error-msg')
      .forEach(elem => elem.classList.add('d-none'))
    let fieldsOk = true
    this.recurrenceInputTargets.forEach(input => {
      if (input.value === '') {
        input.closest('.recurrence-slot-fields').querySelector('.error-msg').classList.remove('d-none')
        fieldsOk = false
      }
    })
    if (fieldsOk) {
      const form = this.recurrenceFieldTarget.closest('#new_guest_request')
      this.submitForm(form, event.currentTarget)
    }
  }

  // Add a method to handle form submission
  submitForm(form, button) {
    const formData = new FormData(form) // Create a FormData object from the form
    // Send an AJAX request to submit the form data
    button.style.pointerEvents = 'none'
    const loader = document.createElement('i')
    loader.classList.add('fas', 'fa-sync-alt')
    button.append(loader)
    fetch(form.action, {
      method: form.method,
      headers: {
        Accept: 'application/javascript' // Spécifiez le format de réponse attendu
      },
      body: formData
    })
      .then(res => res.text())
      .then(data => {
        eval(data)
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error('Error:', error)
      })
  }

  initDatepicker() {
    const flatpickController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('#new_edit_booking_form'),
      'forms--flatpickr'
    )
    flatpickController.connect()
  }
}
