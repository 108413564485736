import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dateRangeInput', 'ownersInput', 'taskKindInput', 'housingInput', 'helpBlock' ]

  validationStayInput(event) {
    // delete all error generate through last export tentative
    document.querySelectorAll('.error-message').forEach(error => error.remove());
    // Input for stay export
    if (this.dateRangeInputTarget.value.length === 0 || this.ownersInputTarget.value.length === 0 ) {
      event.preventDefault();
      event.stopPropagation();
      this.helpBlockTarget.style.display = "block";
      if (this.dateRangeInputTarget.value.length === 0) {
        this.helpBlockTarget.insertAdjacentHTML('beforeend', `<li class='error-message'>${this.dateRangeInputTarget.dataset.error}</li>`);
      } 
      if (this.ownersInputTarget.value.length === 0) {
        this.helpBlockTarget.insertAdjacentHTML('beforeend', `<li class="error-message">${this.ownersInputTarget.dataset.error}</li>`);
      }
      document.querySelector('.fa-sync-alt').remove();
      document.querySelector('.js-form-button').style.pointerEvents = "auto";
    } else {
      this.helpBlockTarget.style.display = "none";
      document.querySelector('.js-form-modal__container').style.display = "none"; // equivalent fadeOut ???
    }
  }

  validationTaskInput(event) {
    // delete all error generate through last export tentative
    document.querySelectorAll('.error-message').forEach(error => error.remove());
    // Input for Task export
    if (this.dateRangeInputTarget.value.length === 0 || this.taskKindInputTarget.value.length === 0 || this.housingInputTarget.value.length === 0 ) {
      event.preventDefault();
      event.stopPropagation();
      this.helpBlockTarget.style.display = "block";
      if (this.dateRangeInputTarget.value.length === 0) {
        this.helpBlockTarget.insertAdjacentHTML('beforeend', `<li class='error-message'>${this.dateRangeInputTarget.dataset.error}</li>`);
      } 
      if (this.taskKindInputTarget.value.length === 0) {
        this.helpBlockTarget.insertAdjacentHTML('beforeend', `<li class="error-message">${this.taskKindInputTarget.dataset.error}</li>`);
      }
      if (this.housingInputTarget.value.length === 0) {
        this.helpBlockTarget.insertAdjacentHTML('beforeend', `<li class="error-message">${this.housingInputTarget.dataset.error}</li>`);
      }
      document.querySelector('.fa-sync-alt').remove();
      document.querySelector('.js-form-button').style.pointerEvents = "auto";
    } else {
      this.helpBlockTarget.style.display = "none";
      document.querySelector('.js-form-modal__container').style.display = "none"; // equivalent fadeOut ???
    }
  }
}