import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'checkBox', 'caretIcon', 'xIcon']
  static values = { label: String }

  preventDefault(event) {
    event.preventDefault()
  }

  populateSelect() {
    if (this.inputTarget.value) {
      this.inputTarget.classList.add("invoicing-filters-select-active")
    } else {
      this.inputTarget.classList.remove("invoicing-filters-select-active")
    }
  }

  populateMultiSelect() {
    let checkboxes = this.checkBoxTargets.filter(checkBox => checkBox.checked)

    if (checkboxes.length > 0) {
      this.caretIconTarget.classList.add("tw-hidden")
      this.xIconTarget.classList.remove("tw-hidden")
      this.inputTarget.value = `${checkboxes.length} ${this.labelValue}`
      this.inputTarget.classList.add("active")
    } else {
      this.caretIconTarget.classList.remove("tw-hidden")
      this.xIconTarget.classList.add("tw-hidden")
      this.inputTarget.value = ''
      this.inputTarget.classList.remove("active")
    }
  }

  clearSelect(event) {
    event.preventDefault()
    event.stopPropagation()

    this.inputTarget.value = ''
    this.inputTarget.classList.remove("invoicing-filters-select-active")
    this.checkBoxTargets.forEach(checkBox => {
      checkBox.checked = false
    })
    this.caretIconTarget.classList.remove("tw-hidden")
    this.xIconTarget.classList.add("tw-hidden")
  }
}
