import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'formErrors']

  connect() {
    if (this.formErrorsTarget.innerHTML !== '') {
      this.formErrorsTarget.scrollIntoView(true)
    }
  }

  submitForm(event) {
    event.preventDefault()
    this.application.getControllerForElementAndIdentifier(this.element, 'phone-number-input').submitForm()
    this.formTarget.submit()
  }
}
