import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'select']

  connect() {
    this.form = this.element
    this.inputTargets.forEach(input => input.addEventListener('input', this.#save))
  }

  clear() {
    this.selectTarget.selectedIndex = 0
    this.#save()
  }

  #save() {
    this.form.requestSubmit()
  }
}
