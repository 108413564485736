import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['perPageInput', 'submitButton']

  submit() {
    this.submitButtonTarget.click()
  }

  changePerPage(event) {
    this.perPageInputTarget.value = event.currentTarget.value
    this.submit()
  }
}
