import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reportingCheckbox', 'checkAll']

  checkAll() {
    this.reportingCheckboxTargets.forEach(checkbox => {
      checkbox.checked = this.checkAllTarget.checked
    })
  }
}
