import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'cardsList',
    'cardSkeletton',
    'dataCardMassageComponent',
    'errorMsg',
    'formMassage',
    'modalContent',
    'parentInput',
    'radioBtn',
    'radioInputSup',
    'selectSearchInput',
    'inputIds'
  ]

  static values = {
    nestedModel: String,
    model: String
  }

  guestChoose = null
  currentFormIndex = null
  valueInputs = []

  connect() {
    if (this.errorMsgTargets.length) {
      this.errorMsgTargets.forEach(error => {
        error.classList.add('d-none')
      })
    }
  }

  displayCardDateAndHour(cardElement, currentForm) {
    let titleCard = [...currentForm.querySelectorAll('[data-modal-hideform-gencard-target="dateHourValues"]')].map(input => input.value).join(' ')
    let dateCard = currentForm
      .querySelector('[data-modal-hideform-gencard-target="catchValue dateValues"]')
      ._flatpickr.selectedDates[0].toLocaleDateString()
    let hourCard = currentForm
      .querySelector('[data-modal-hideform-gencard-target="catchValue timeValues"]')
      ._flatpickr.selectedDates[0].toTimeString()
      .slice(0, 5)
    let nameToBeneficiary
    if (currentForm.querySelectorAll('.form__row-radio')[0].classList.contains('active')) {
      nameToBeneficiary = currentForm.querySelector('.form__row-radio.active input').getAttribute('guest-name')
    } else {
      nameToBeneficiary = currentForm.querySelector('.form__row-radio-supinput input').value
    }
    cardElement.querySelector('.fullname').innerText = nameToBeneficiary
    cardElement.querySelector('.date-hour').innerText = dateCard + ' - ' + hourCard
  }

  resetRadios() {
    const totalForm = this.formMassageTargets.length
    const lastForm = this.formMassageTargets[totalForm - 1]
    if (totalForm) {
      const allRadioBtns = lastForm.querySelectorAll('.form__row-radio')
      if (!allRadioBtns[0].classList.contains('active')) {
        allRadioBtns.forEach(radio => {
          radio.classList.remove('active')
          radio.querySelector('.form__row-radio-input').checked = false
        })
        allRadioBtns[0].classList.add('active')
        allRadioBtns[0].querySelector('.form__row-radio-input').checked = true
      }
    }
  }

  setInputName(currentForm) {
    currentForm.querySelectorAll('input, select').forEach(input => {
      if (!input.dataset.inputName && !input.name) return
      if (!input.dataset.inputName && input.name) {
        input.setAttribute('name', input.name.replace(/\d+/g, this.formMassageTargets.length))
        input.value = input.dataset.value || ''
        return
      }
      let name = `${this.modelValue}[${this.nestedModelValue}][${this.formMassageTargets.length}][${input.dataset.inputName}]`
      input.setAttribute('name', name)
    })
  }

  upsertCard(currentForm, requestId) {
    const alreadyExist = this.dataCardMassageComponentTargets.some(
      card => card.dataset.index === currentForm.dataset.index && currentForm.dataset.index
    )
    if (alreadyExist) {
      const currentCard = this.dataCardMassageComponentTargets.find(
        card => card.dataset.index === currentForm.dataset.index && currentForm.dataset.index
      )
      this.displayCardDateAndHour(currentCard, currentForm)
      currentCard.classList.remove('d-none')
      this.dataCardMassageComponentTargets.forEach((element, index) => {
        element.querySelector('.index-code').innerText = ` ${index + 1}`
      })
    } else {
      const newCard = this.cardSkelettonTarget.firstElementChild.cloneNode(true)
      newCard.setAttribute('data-index', this.formMassageTargets.length)
      newCard.setAttribute('data-id', requestId)
      this.displayCardDateAndHour(newCard, currentForm)
      this.cardsListTarget.lastElementChild.insertAdjacentElement('afterEnd', newCard)
      newCard.classList.remove('d-none')
      this.dataCardMassageComponentTargets.forEach((element, index) => {
        element.querySelector('.index-code').innerText = ` ${index + 1}`
      })
    }
  }

  verifyForm(event) {
    event.preventDefault()
    const currentForm = this.formMassageTargets.find(elem => elem.dataset.index === event.currentTarget.dataset.index)
    const indexForm = currentForm.dataset.index
    let valid = true

    let allInputsToVerify = currentForm.querySelectorAll('.input.required input')
    allInputsToVerify.forEach(input => {
      if (input.type !== 'hidden' && input.value === '') {
        input.closest('.form__field-content').querySelector('span').classList.remove('d-none')
        valid = false
      } else {
        input.closest('.form__field-content').querySelector('span').classList.add('d-none')
      }
    })

    this.radioInputSupTargets.forEach(radio => {
      if (radio.querySelector('input.required') && radio.querySelector('input.required').value === '') {
        radio.querySelector('span').classList.remove('d-none')
        valid = false
      }
    })

    if (valid) {
      this.setInputName(currentForm)
      this.submitRequest(currentForm)
      currentForm.classList.add('d-none')
      this.modalContentTarget.classList.add('d-none')
      document.querySelector('[data-modal-target="subModal"]').classList.remove('overflow--hidden')
      const goToCards = document.getElementById('anchor-cards-add')
      goToCards.scrollIntoView({ behavior: 'instant' }, true)
    }
  }

  submitRequest(currentForm) {
    const formDataValue = [...currentForm.querySelectorAll('input, textarea')].filter(elem => elem.name.length).map(elem => [elem.name, elem.value])
    const formData = new FormData()
    formDataValue.forEach(field => {
      const RegexName = /[[](\w{2,})[\]]/i
      const name = `guest_request${RegexName.exec(field[0])[0]}`
      formData.append(name, field[1])
    })

    let method = 'POST'
    let path = '/api/guest_requests'
    if (formData.get('guest_request[id]')?.length) {
      method = 'PATCH'
      path = `/api/guest_requests/${formData.get('guest_request[id]')}`
    }

    fetch(path, {
      method,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: formData
    })
      .then(response => response.json())
      .then(guestRequest => {
        if (guestRequest.errors) window.location.reload()
        currentForm.querySelector('#guest_request_id').value = guestRequest.id
        if (method === 'POST') {
          const input = this.inputIdsTarget.cloneNode(true)
          input.value = guestRequest.id
          this.inputIdsTarget.appendChild(input)
        }
        this.upsertCard(currentForm, guestRequest.id)
        return guestRequest.id
      })
  }

  deleteRequest(event) {
    const requestId = event.currentTarget.parentNode.dataset?.id
    if (!requestId) return
    fetch(`/api/guest_requests/${requestId}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).finally(() => {
      this.inputIdsTargets.find(input => input.value.match(requestId)).remove()
    })
  }
}
