import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['defaultPrice', 'providerPrice']

  compute() {
    const defaultPrice = parseFloat(this.defaultPriceTarget.value.replace(',', '.')) || 0

    this.providerPriceTargets.forEach((providerPrice) => {
      providerPrice.setAttribute('placeholder', this.#formatValue(defaultPrice))
    })
  }

  #formatValue(value) {
    return value.toFixed(2).replace('.', ',')
  }
}
