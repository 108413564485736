import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['card', 'parentCards', 'checkbox']

  connect() {
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked === true) {
        checkbox.parentNode.classList.add('active')
      }
    })
  }

  manageActiveClass(event) {
    event.preventDefault()
    const currentTarget = event.currentTarget
    currentTarget.classList.toggle('active')
    if (currentTarget.classList.contains('active')) {
      currentTarget.querySelector('input').checked = true
    } else {
      currentTarget.querySelector('input').checked = false
    }
  }

  verifyCheckbox(event) {
    event.preventDefault()
    let fieldsOk = false
    this.parentCardsTargets.forEach(parent => {
      parent.querySelectorAll('.checkbox-classic').forEach(checkbox => {
        if (checkbox.checked === true) {
          fieldsOk = true
        }
      })
      if (fieldsOk === false) {
        parent.querySelector('.error-msg').classList.remove('d-none')
      }
    })
  }
}
