import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { min: Number, max: Number }

  validate(event) {
    const input = event.currentTarget
    const row = input.closest('.row')
    let formatValue = null
    let format = null
    if (row) {
      format = row.querySelector('.selectize-input')
    }
    if (format) {
      formatValue = format.querySelector('.item').textContent
    }
    if (formatValue && formatValue === '%' && input) {
      this.percentage(input)
    } else {
      if (input && this.hasMinValue) {
        const inputValue = parseFloat(input.value)
        if (inputValue < this.minValue) {
          input.value = this.minValue
        }
        if (this.hasMaxValue && inputValue > this.maxValue) {
          input.value = this.maxValue
        }
      }
    }
  }

  percentage(input) {
    if (input) {
      const inputValue = parseFloat(input.value)
      if (inputValue < 0) {
        input.value = 0
      }
      if (inputValue > 100) {
        input.value = 100
      }
    }
  }

  switchToPercentage() {
    const target = event.currentTarget
    const format = target.value
    if (format && format === 'percent') {
      const input = target.closest('.row').querySelector('.numeric.decimal.optional.form__field-model')
      const inputValue = parseFloat(input.value)
      if (inputValue > 100) {
        input.value = 100
      }
    }
  }
}
